const publicDeviceModel = {
  'pages.material.material.device-model.addDevice': '新建器件',
  'pages.material.material.device-model.recall': '撤回',
  'pages.material.material.device-model.deviceModelId': '器件ID',
  'pages.material.material.device-model.length': '长度',
  'pages.material.material.device-model.width': '宽度',
  'pages.material.material.device-model.height': '高度',
  'pages.material.material.device-model.lengthUnit': '长度(mm)',
  'pages.material.material.device-model.widthUnit': '宽度(mm)',
  'pages.material.material.device-model.heightUnit': '高度(mm)',
  'pages.material.material.device-model.deviceModelNo': '编码',
  'pages.material.material.device-field.approveRemark': '审核意见',
  'pages.material.material.device-field.deviceModelSourceName0': '手动创建',
  'pages.material.material.device-field.deviceModelSourceName1': 'DFM上传',
  'pages.material.material.device-model.only.show.uncorrelated': '仅展示未关联',

  'pages.material.material.device-model.binding.title': '绑定提示',

  'pages.material.material.device-model.binding.confirm': '确认',

  'pages.material.material.device-model.binding.title.hint1':
    '当前选择物料中，存在物料已绑定其他器件模型。',
  'pages.material.material.device-model.binding.title.hint2':
    '如继续操作绑定，将解除原绑定器件模型关联，并绑定当前器件模型',
  'pages.material.material.device-model.binding.title.hint3': '是否继续操作绑定?',
}
export default publicDeviceModel
