const productTable = {
  'pages.order.components.product.name': '产品名称',
  'pages.order.components.product.materialNo': '物料编号',
  'pages.order.components.product.seriesName': '系列',
  'pages.order.components.product.categoryName': '品类',
  'pages.order.components.product.cabinetTypeName': '商品类型',
  'pages.order.components.product.orderNo': '订货号',
  'pages.order.components.product.customizedNo': '定制备注',
  'pages.order.components.product.materialShortNo': '订货代码',
  'pages.order.components.product.model': '型号',
  'pages.order.components.product.specification': '规格',
  'pages.order.components.product.brandName': '品牌',
  'pages.order.components.product.price': '单价(元)',
  'pages.order.components.product.discountPrice': '优惠后单价(元)',
  'pages.order.components.product.quantity': '数量',
  'pages.order.components.product.subtotalAmount': '金额小计(元)',
  'pages.order.components.product.expectedTime': '期望到货日期',
  'pages.order.components.product.estimatedShippingTime': '预计发货日期',
  'pages.order.components.product.remark': '备注',
  'pages.order.components.product.businessRemark': '商务备注',
  'pages.order.components.product.customerRemark': '客户备注',
  'pages.order.components.product.uploadDocument': '上传文档',
}

export default productTable
