const settingsOrganization = {
  'pages.settings.organization.add.organization': '新建组织',
  'pages.settings.organization.superior.organization': '上级组织',
  'pages.settings.organization.organization.name': '组织名称',
  'pages.settings.organization.organization.type': '形态',
  'pages.settings.organization.organization.address': '地址',
  'pages.settings.organization.organization.owner': '拥有者',
  'pages.settings.organization.organization.owner.phone': '拥有者电话',
  'pages.settings.organization.organization.main.verify': '主体认证',
  'pages.settings.organization.organization.anew.verify': '重新认证',
  'pages.settings.organization.organization.abbreviation.name': '组织简称',
  'pages.settings.organization.organization.code': '组织代码',
  'pages.settings.organization.organization.concat.name': '联系人',
  'pages.settings.organization.organization.concat.phone': '联系电话',
  'pages.settings.organization.organization.approval.status': '审批状态',
  'pages.settings.organization.organization.basic.information': '基本信息',
  'pages.settings.organization.organization.invoice.information': '开票信息',
  'pages.settings.organization.organization.company.name': '公司名称',
  'pages.settings.organization.organization.company.address': '公司地址',
  'pages.settings.organization.organization.company.detail.address': '详细地址',
  'pages.settings.organization.organization.social.credit.code': '统一社会信用代码',
  'pages.settings.organization.organization.company.register.address': '公司注册地址',
  'pages.settings.organization.organization.company.register.phone': '公司注册电话',
  'pages.settings.organization.organization.deposit.bank': '开户银行',
  'pages.settings.organization.organization.deposit.bank.account': '开户银行账户',
  'pages.settings.organization.organization.company.certificate.address': '证件上所在城市',
  'pages.settings.organization.organization.corporate.identity.card': '法人身份证号码',
  'pages.settings.organization.organization.business.license': '营业执照',
  'pages.settings.organization.organization.official.letter': '认证公函',
  'pages.settings.organization.organization.more.operation': '更多操作',
  'pages.settings.organization.organization.menu.configuration': '菜单配置',
  'pages.settings.organization.organization.turn.over': '移交',
  'pages.settings.organization.organizations.turn.over.person': '移交人',
  'pages.settings.organization.organization.delete.company': '删除企业',
  'pages.settings.organization.organization.not.verify': '企业未认证,',
  'pages.settings.organization.organization.immediately.verify': '立即开始认证,',
  'pages.settings.organization.organization.legal.person': '法定代表人',
  'pages.settings.organization.organization.legal.select': '请选择上级组织，不选则视为无上级组织',
  'pages.settings.organization.organization.phone.format.error': '电话格式不正确',
  'pages.settings.organization.organization.phone.format':
    '选择的文件中包含不支持的格式，只支持.jpg,.jpeg,.png的格式',
  'pages.settings.organization.organization.phone.file': '文件不能超过2.00MB',
  'pages.settings.organization.organization.department': '组织结构',
  'pages.settings.organization.organization.warning':
    '一旦你删除了企业，企业内所有订单、部门、成员中所有内容等都将会被永久删除。这是一个不可恢复的操作，请谨慎对待！',
  'pages.settings.organization.organizations.select.turn.over.person': '选择移交人',

  'pages.settings.organization.organizations.enterprise.information.audit': '企业信息审核中',
  'pages.settings.organization.organizations.audit.reject': '审批被驳回',
  'pages.settings.organization.organizations.anew.authentication': '请重新认证',

  'pages.settings.organization.drawer.shipping.address': '收货地址',
  'pages.settings.organization.drawer.add.new.address': '添加新地址',
  'pages.settings.organization.drawer.update.address': '修改地址',
  'pages.settings.organization.drawer.shipping.address.field.province': '所在省份',
  'pages.settings.organization.drawer.shipping.address.field.detail.address': '详细地址',
  'pages.settings.organization.drawer.shipping.address.field.consignee': '收货人',
  'pages.settings.organization.drawer.shipping.address.field.consignee.phone': '收货人手机',
  'pages.settings.organization.drawer.shipping.address.field.postal.code': '邮政编码',
  'pages.settings.organization.drawer.shipping.address.field.default.address': '默认地址',
  'pages.settings.organization.drawer.shipping.address.operation.default.address': '设为默认地址',

  'pages.settings.organization.drawer.invoice.info': '发票信息',
  'pages.settings.organization.drawer.add.invoice.info': '添加发票',
  'pages.settings.organization.drawer.update.invoice.info': '修改发票',
  'pages.settings.organization.drawer.add.vat.invoice.dedicated': '增值税专用发票',
  'pages.settings.organization.drawer.add.vat.invoice.common': '增值税普通发票',
  'pages.settings.organization.drawer.invoice.info.field.name': '单位名称',
  'pages.settings.organization.drawer.invoice.info.modal.personal.name': '个人名称',
  'pages.settings.organization.drawer.invoice.info.modal.personal.name.hint':
    '请填写“个人”或您的姓名',
  'pages.settings.organization.drawer.invoice.info.field.unifiedSocialCreditCode': '纳税人识别号',
  'pages.settings.organization.drawer.invoice.info.field.unifiedSocialCreditCodes': '纳税人号',
  'pages.settings.organization.drawer.invoice.info.field.invoiceContent': '发票内容',
  'pages.settings.organization.drawer.invoice.info.field.consignee': '收票人名称',
  'pages.settings.organization.drawer.invoice.info.field.consignee.phone': '收票人手机',
  'pages.settings.organization.drawer.invoice.info.field.consignee.address': '收票人省份',
  'pages.settings.organization.drawer.invoice.info.field.streetAddress': '详细地址',
  'pages.settings.organization.drawer.invoice.info.field.consignee.email': '收票人邮箱',
  'pages.settings.organization.drawer.invoice.info.field.register.phone': '注册电话',
  'pages.settings.organization.drawer.invoice.info.field.bankName': '开户银行',
  'pages.settings.organization.drawer.invoice.info.field.bankAccount': '银行账号',
  'pages.settings.organization.drawer.invoice.info.field.registerAddress': '注册地址',
  'pages.settings.organization.drawer.invoice.info.modal.hint': '第一步：填写或核对公司信息',
  'pages.settings.organization.drawer.invoice.info.modal.modal.next': '下一步',
  'pages.settings.organization.drawer.invoice.info.modal.hint.next': '第二步：填写收票人信息',
  'pages.settings.organization.drawer.invoice.info.modal.product.name': '商品名称',
  'pages.settings.organization.drawer.invoice.info.modal.product.category': '商品类别',
  'pages.settings.organization.drawer.invoice.info.modal.modal.up': '上一步',
  'pages.settings.organization.drawer.invoice.info.modal.hint.invoiceContent':
    '发票内容将显示详细商品名称与价格信息',
  'pages.settings.organization.drawer.invoice.info.modal.ordinary.hint':
    '修改单位注册地址、电话、开户银行及账号',
  'pages.settings.organization.drawer.invoice.info.modal.ordinary.optional.items': '更多选填项',
  'pages.settings.organization.drawer.invoice.info.modal.dedicated.hint.one':
    '请确认您要添加这张发票。',
  'pages.settings.organization.drawer.invoice.info.modal.dedicated.hint.two':
    '检测到发票中填写的单位名称  ({updateName})  和您所在组织的名称  ({name})  不一致。',
  'pages.settings.organization.drawer.invoice.info.modal.dedicated.hint.three':
    '发票单位名称和组织名称不统一可能会造成收到的发票信息有误,请谨慎操作',

  'pages.settings.organization.drawer.invoice.info.modal.dedicated.button.one': '添加发票',
  'pages.settings.organization.drawer.invoice.info.modal.dedicated.button.two':
    '添加并修改组织名称(推荐)',

  'pages.settings.organization.drawer.invoice.info.modal.update.dedicated.hint.one':
    '请确认您要修改这张发票。',
  'pages.settings.organization.drawer.invoice.info.modal.update.dedicated.button.one': '保存发票',
  'pages.settings.organization.drawer.invoice.info.modal.update.dedicated.button.two':
    '保存并修改组织名称(推荐)',
  'pages.settings.organization.organization.passwordSetup': '交易密码',
  'pages.settings.organization.organization.inputPassword': '输入密码',
  'pages.settings.organization.organization.confirmPassword': '确认密码',
  'pages.settings.organization.organization.inputCord': '输入验证码',
  'pages.settings.organization.organization.info': '请输入6位数字组成的密码',
  'pages.settings.organization.organization.noSame': '两次密码不一致',
  'pages.settings.organization.organization.resetPassword': '重置密码',
  'pages.settings.organization.organization.setPassword': '设置密码',
  'pages.settings.organization.organization.noPassword': '当前暂未设置密码，下单前请设置支付密码',
  'pages.settings.organization.organization.havePassword': '支付密码已设置，如忘记密码请点击',
  'pages.settings.organization.organization.resetVerification': '重置支付密码需要安全验证。',
  'pages.settings.organization.organization.setVerification': '设置支付密码需要安全验证。',
  'pages.settings.organization.organization.getCodeVerification': '收到后请输入验证码校验。',
  'pages.settings.organization.organization.ownerBy': '当前组织拥有者为',
  'pages.settings.organization.organization.phoneBy': '手机号为',
  'pages.settings.organization.organization.send': '，我们将向此手机号发送验证码',
  'pages.settings.organization.organization.passwordInfo': '此交易密码仅在平台下单时，余额支付使用',
}

export default settingsOrganization
