const contract = {
  'pages.finance.balance.deposit.account.field.accountNo': '客户预存账户',
  'pages.finance.balance.deposit.account.field.customerName': '客户',
  'pages.finance.balance.deposit.account.field.accountBalance': '账户余额(元)',
  'pages.finance.balance.deposit.account.field.accountBalance1': '全部余额',
  'pages.finance.balance.deposit.account.field.availableBalance': '可用余额(元)',
  'pages.finance.balance.deposit.account.field.availableBalance1': '可用余额',
  'pages.finance.balance.deposit.account.field.blockedBalance': '冻结余额(元)',
  'pages.finance.balance.deposit.account.field.blockedBalance1': '冻结余额',
  'pages.finance.balance.deposit.account.field.businessPersonName': '商务负责人',
  'pages.finance.balance.deposit.account.field.salesPersonName': '销售负责人',
  'pages.finance.balance.deposit.account.field.accountFlow': '预存账户收支记录',
  'pages.finance.balance.deposit.account.field.accountFlow1': '预存账户记录',
  'pages.finance.balance.deposit.account.field.viewMore': '查看更多',

  'pages.finance.balance.deposit.account.drawer.field.changeTypeName': '收支类型',
  'pages.finance.balance.deposit.account.drawer.field.changeFlowNo': '收支流水单号',
  'pages.finance.balance.deposit.account.drawer.field.changeAmount': '收支金额(元)',
  'pages.finance.balance.deposit.account.drawer.field.oldAmount': '收支前余额(元)',
  'pages.finance.balance.deposit.account.drawer.field.newAmount': '收支后余额(元)',
  'pages.finance.balance.deposit.account.drawer.field.createdTime': '收支时间',

  'pages.finance.balance.deposit.account.record.field.accountNo': '客户预存账户', // 少
  'pages.finance.balance.deposit.account.record.field.customerName': '客户',
  'pages.finance.balance.deposit.account.record.field.changeTypeName': '收支类型',
  'pages.finance.balance.deposit.account.record.field.changeFlowNo': '收支流水单号',
  'pages.finance.balance.deposit.account.record.field.changeAmount': '收支金额(元)',
  'pages.finance.balance.deposit.account.record.field.oldAmount': '收支前余额(元)',
  'pages.finance.balance.deposit.account.record.field.newAmount': '收支后余额(元)',
  'pages.finance.balance.deposit.account.record.field.createdTime': '收支时间',

  'pages.finance.balance.payment.history.field.paymentFlowNo': '支付流水号',
  'pages.finance.balance.payment.history.field.paymentNo': '支付单号',
  'pages.finance.balance.payment.history.field.salesOrderNo': '订单号',
  'pages.finance.balance.payment.history.field.paymentChannelName': '支付方式',
  'pages.finance.balance.payment.history.field.paymentTypeName': '支付类型',
  'pages.finance.balance.payment.history.field.paymentAmount': '支付金额(元)',
  'pages.finance.balance.payment.history.field.receivableAmount': '应收金额(元)',
  'pages.finance.balance.payment.history.field.receivedAmount': '已收金额(元)',
  'pages.finance.balance.payment.history.field.receiptsName': '单据状态',
  'pages.finance.balance.payment.history.field.restReceivableAmount': '剩余应收(元)',
  'pages.finance.balance.payment.history.field.paymentTime': '支付时间',

  'pages.finance.balance.bank-flow.flowNo': '流水号',
  'pages.finance.balance.bank-flow.accountName': '账户名称',
  'pages.finance.balance.bank-flow.bankAccount': '银行账户',
  'pages.finance.balance.bank-flow.bankName': '银行名称',
  'pages.finance.balance.bank-flow.state': '处理状态',
  'pages.finance.balance.bank-flow.income': '收入金额(元)',
  'pages.finance.balance.bank-flow.expenseAmount': '支出金额(元)',
  'pages.finance.balance.bank-flow.abstractInfo': '摘要',
  'pages.finance.balance.bank-flow.flowTime': '流水时间',
  'pages.finance.balance.bank-flow.natchCustomer': '匹配客户',
  'pages.finance.balance.bank-flow.pull': '手动拉取记录',
  'pages.finance.balance.bank-flow.statusId1': '待处理',
  'pages.finance.balance.bank-flow.statusId2': '已处理',

  'pages.finance.balance.type.bank.flow': '银行流水',
  'pages.finance.balance.type.payment.flow': '支付流水',

  'pages.finance.balance.type.total.payment': '整单支付',
  'pages.finance.balance.type.portion.payment': '部分支付',

  'pages.finance.balance.payment.balance': '余额',
  'pages.finance.balance.payment.ali-pay': '支付宝',
  'pages.finance.balance.payment.WeChat': '微信',
  'pages.finance.balance.payment.match': '匹配',
  'pages.finance.balance.payment.in': '在',
  'pages.finance.balance.payment.confirmMatch1': ' 请确认您要匹配账户 ',
  'pages.finance.balance.payment.confirmMatch11': '  银行账户',
  'pages.finance.balance.payment.confirmMatch2': '发起的收入金额',
  'pages.finance.balance.payment.confirmMatch3': '元，这笔金额将会计入客户名称',
  'pages.finance.balance.payment.confirmMatch4': ' 的账户余额。',
  'pages.finance.balance.payment.confirmMatch5':
    ' 请仔细核对账户名称和银行账户，确保信息无误后进行操作。',

  'pages.finance.balance.refund-record.refundFlowNo': '退款流水',
  'pages.finance.balance.refund-record.refundNo': '退款单号',
  'pages.finance.balance.refund-record.salesOrderNo': '订单号',
  'pages.finance.balance.refund-record.paymentNo': '支付单号',
  'pages.finance.balance.refund-record.paymentAmount': '支付金额(元)',
  'pages.finance.balance.refund-record.refundChannelName': '退款方式',
  'pages.finance.balance.refund-record.refundAmount': '退款金额(元)',
  'pages.finance.balance.refund-record.refundTime': '退款时间',
}

export default contract
