const workbench = {
  'pages.settings.workbench.launch.workFlowNo': '审核单号',
  'pages.settings.workbench.launch.name': '审核名称',
  'pages.settings.workbench.launch.type': '审核类型',
  'pages.settings.workbench.launch.currentAuditorName': '当前审核人',
  'pages.settings.workbench.launch.auditNodeName': '审核节点',
  'pages.settings.workbench.launch.creatorName': '发起人',
  'pages.settings.workbench.launch.createdTime': '发起时间',
  'pages.settings.workbench.launch.order': '订单审核',
  'pages.settings.workbench.launch.express': '物流审核',
  'pages.settings.workbench.launch.document': '文档审核',
  'pages.settings.workbench.launch.customer': '客户审批',
  'pages.settings.workbench.launch.agree': '已同意',
  'pages.settings.workbench.launch.refuse': '已拒绝',
  'pages.settings.workbench.launch.repeal': '已撤销',
}
export default workbench
