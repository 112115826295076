const modal = {
  'pages.material.components.material-selector-modal.title': '添加物料',
  'pages.material.components.material-selector-modal.treeTitle': '品类',
  'pages.material.components.material-selector-modal.tip': '请先选择品类',
  'pages.material.components.material-selector-modal.params': '规格参数筛选',
  'pages.material.components.material-selector-modal.searchLabel': '物料编号或订货号',
  'pages.material.components.material-selector-modal.field.materialName': '物料名称',
  'pages.material.components.material-selector-modal.field.materialNo': '物料编号',
  'pages.material.components.material-selector-modal.field.orderNo': '订货号',
  'pages.material.components.material-selector-modal.field.category': '品类',
  'pages.material.components.material-selector-modal.field.brand': '品牌',
  'pages.material.components.material-selector-modal.field.series': '系列',
  'pages.material.components.material-selector-modal.action.switchToSelector': '切换到选型',
  'pages.material.components.material-selector-modal.action.switchToSearch': '切换到搜索',
  'pages.material.components.material-selector-modal.action.clearFilter': '清空',
  'pages.material.components.material-selector-modal.noAttributeTip': '无可选规格',
  'pages.material.components.material-selector-modal.continuousAddition': '连续添加',

  'pages.material.components.material-selector-modal.select.materials': '选择物料',
}

export default modal
