const deviceModel = {
  'pages.material.material.device-model.addDevice': '新建器件',
  'pages.material.material.device-model.recall': '撤回',
  'pages.material.material.device-model.deviceModelId': '器件ID',
  'pages.material.material.device-model.length': '长度',
  'pages.material.material.device-model.width': '宽度',
  'pages.material.material.device-model.height': '高度',
  'pages.material.material.device-model.lengthUnit': '长度(mm)',
  'pages.material.material.device-model.widthUnit': '宽度(mm)',
  'pages.material.material.device-model.heightUnit': '高度(mm)',
  'pages.material.material.device-model.deviceModelNo': '编码',
  'pages.material.material.device-field.approveRemark': '审核意见',
  'pages.material.material.device-field.deviceModelSourceName': '器件来源',
  'pages.material.material.device-field.deviceModelSourceName0': '手动创建',
  'pages.material.material.device-field.deviceModelSourceName1': 'DFM上传',
  'pages.material.material.device-field.deviceModelSourceName2': '公库同步',
}
export default deviceModel
