const productPackage = {
  'pages.platform.resources.productPackage.name': '产品包名称',
  'pages.platform.resources.productPackage.type': '类型',
  'pages.platform.resources.productPackage.default.authorization': '默认授权',
  'pages.platform.resources.productPackage.default.defaultRoleType': '默认授权角色',

  'pages.platform.resources.productPackage.type0': '基础功能',
  'pages.platform.resources.productPackage.type1': 'EB',
  'pages.platform.resources.productPackage.type2': 'DFM',

  'pages.platform.resources.productPackage.add': '新建产品包',

  'pages.platform.resources.productPackage.delete.hint1': '请确认您要删除这个产品包。',
  'pages.platform.resources.productPackage.delete.hint2': '这个产品包将会从产品包列表内移除。',
  'pages.platform.resources.productPackage.delete.hint3':
    '如果您不想让组织拥有这个产品包的权限，通常是在组织管理中取消授权，而不是删除。',

  'pages.platform.resources.productPackage.drawer.function': '功能列表',

  'pages.platform.resources.productPackage.drawer.version': '版本设置',

  'pages.platform.resources.productPackage.drawer.version.name': '版本名称',
  'pages.platform.resources.productPackage.drawer.version.subhead': '副标题',
  'pages.platform.resources.productPackage.drawer.version.function': '功能点',

  'pages.platform.resources.productPackage.drawer.modal.delete.version.function':
    '请确认您要删除这个功能点。',

  'pages.platform.resources.productPackage.drawer.version.state': '状态',

  'pages.platform.resources.productPackage.delete.version.hint1': '请确认您要删除这个版本。',
  'pages.platform.resources.productPackage.delete.version.hint2': '这个版本将会从列表内移除。',
  'pages.platform.resources.productPackage.delete.version.hint3':
    '如果你不想用这个版本，通常是停用，而不是删除。',
}

export default productPackage
