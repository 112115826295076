const myCustomer = {
  'pages.customer.customer.myCustomer.field.management': '客户管理',
  'pages.customer.customer.myCustomer.field.sourceIdList': '来源',
  'pages.customer.customer.myCustomer.field.name': '客户名称',
  'pages.customer.customer.myCustomer.field.concat': '客户联系人',
  'pages.customer.customer.myCustomer.field.concat.phone': '联系人电话',
  'pages.customer.customer.myCustomer.field.customer.phone': '客户电话',
  'pages.customer.customer.myCustomer.field.sell.on.access': '销售负责人',
  'pages.customer.customer.myCustomer.field.sell.phone': '销售电话',
  'pages.customer.customer.myCustomer.field.business.on.access': '商务负责人',
  'pages.customer.customer.myCustomer.field.business.phone': '商务电话',
  'pages.customer.customer.myCustomer.field.technology.on.access': '技术负责人',
  'pages.customer.customer.myCustomer.field.technology.phone': '技术电话',
  'pages.customer.customer.myCustomer.field.consignee': '收货人',
  'pages.customer.customer.myCustomer.field.consignee.phone': '收货人电话',
  'pages.customer.customer.myCustomer.field.consignee.address': '公司地址',
  'pages.customer.customer.myCustomer.field.detail.address': '详细地址',
  'pages.customer.customer.myCustomer.field.subject.authentication': '主体认证',
  'pages.customer.customer.myCustomer.field.description': '描述',
  'pages.customer.customer.myCustomer.add.customer': '新建客户',
  'pages.customer.customer.myCustomer.matching.organization': '匹配组织',
  'pages.customer.customer.myCustomer.customer.detail': '客户详情',
  'pages.customer.customer.myCustomer.customer.basic': '基本信息',
  'pages.customer.customer.myCustomer.customer.consignee.address': '收货地址',
  'pages.customer.customer.myCustomer.customer.invoice.info': '开票信息',
  'pages.customer.customer.myCustomer.customer.principal.authentication': '主体认证',
  'pages.customer.customer.myCustomer.customer.company.name': '公司名称',
  'pages.customer.customer.myCustomer.customer.modal.organization.name': '组织名称',
  'pages.customer.customer.myCustomer.customer.modal.contact': '联系人',
  'pages.customer.customer.myCustomer.customer.modal.contact.phone': '联系电话',

  'pages.customer.customer.myCustomer.customer.credit.code': '统一社会信用代码',
  'pages.customer.customer.myCustomer.customer.company.register.address': '公司注册地',
  'pages.customer.customer.myCustomer.customer.company.register.phone': '公司注册电话',
  'pages.customer.customer.myCustomer.customer.deposit.bank': '开户银行',
  'pages.customer.customer.myCustomer.customer.deposit.bank.account': '开户银行账户',
  'pages.customer.customer.myCustomer.customer.certificate.city': '证件上所在城市',
  'pages.customer.customer.myCustomer.customer.legal.person': '法定代表人',
  'pages.customer.customer.myCustomer.customer.identity.card': '法人身份证号码',
  'pages.customer.customer.myCustomer.customer.business.license': '营业执照',
  'pages.customer.customer.myCustomer.phone.format.error': '电话格式不正确',
  'pages.customer.customer.myCustomer.name.empty.hint': '客户名称为空，请输入',
  'pages.customer.customer.myCustomer.name.empty.electronicInvoice': '接受电子发票',
  'pages.customer.customer.myCustomer.name.empty.accept': '接受',
  'pages.customer.customer.myCustomer.name.empty.noAccept': '不接受',
  'pages.customer.customer.myCustomer.name.drawer.clearing.form': '结算方式',
  'pages.customer.customer.myCustomer.name.drawer.contract.template': '销售合同',
  'pages.customer.customer.myCustomer.name.drawer.view.audit.history': '查看审核历史',

  'pages.customer.customer.myCustomer.list.modal.delete.customer': '删除客户',
  'pages.customer.customer.myCustomer.list.modal.delete.hint.one': '请确认您要删除这个客户',
  'pages.customer.customer.myCustomer.list.modal.delete.hint.two':
    '删除该客户后，将无法恢复，是否确认？',

  'pages.customer.customer.myCustomer.name.modal.set.clearing.form': '设置专属结算方式',
  'pages.customer.customer.myCustomer.name.modal.select.clearing.form': '选择结算方式',
  'pages.customer.customer.myCustomer.name.modal.current.clearing.form': '当前结算方式',
  'pages.customer.customer.myCustomer.name.modal.save.clearing.form': '保存结算方式',
  'pages.customer.customer.myCustomer.name.modal.settlement.hint1': '请确认您要保存这个结算方式。',
  'pages.customer.customer.myCustomer.name.modal.settlement.hint2':
    '确认保存后，该结算方式将进入审核流程，审核通过后才会生效。',
  'pages.customer.customer.myCustomer.name.modal.settlement.hint3':
    '在审核通过前，该客户将继续使用 ',
  'pages.customer.customer.myCustomer.name.modal.settlement.hint4': '请输入结算方式变更理由',
  'pages.customer.customer.myCustomer.name.modal.field.settlement.node.name': '结算节点名称',
  'pages.customer.customer.myCustomer.name.modal.field.settlement.period': '结算周期',
  'pages.customer.customer.myCustomer.name.modal.field.single.settlement.node.ratio':
    '单节点结算比例',
  'pages.customer.customer.myCustomer.name.modal.field.total.settlement.node.ratio': '累计结算比例',

  'pages.customer.customer.myCustomer.name.modal.set.exclusive.contract': '设置专属合同',
  'pages.customer.customer.myCustomer.name.modal.select.exclusive.contract': '选择专属合同',
  'pages.customer.customer.myCustomer.name.modal.save.exclusive.contract': '保存专属合同',
  'pages.customer.customer.myCustomer.name.modal.contract.hint1':
    '请确认您要将这个合同模板设置为默认。',
  'pages.customer.customer.myCustomer.name.modal.contract.hint2':
    '您只能将一条合同模板设为默认，修改后其余合同模板将自动修改为否。',
  'pages.customer.customer.myCustomer.name.modal.contract.hint3':
    '这条合同模板将成为客户的默认合同模板',
  'pages.customer.customer.myCustomer.name.modal.contract.hint4': '请输入客户合同变更理由',

  'pages.customer.customer.myCustomer.name.drawer.workFlowNo': '审核单号',
  'pages.customer.customer.myCustomer.name.drawer.creatorName': '发起人',
  'pages.customer.customer.myCustomer.name.drawer.createdTime': '发起时间',
  'pages.customer.customer.myCustomer.name.drawer.currentAuditorName': '当前审核人',
  'pages.customer.customer.myCustomer.name.drawer.auditNodeName': '待办节点',

  'pages.customer.customer.myCustomer.name.drawer.stateName': '审核结果',
  'pages.customer.customer.myCustomer.name.drawer.history': '当前无审核历史',

  'pages.customer.customer.myCustomer.modal.hint.one':
    '请确认您要把客户<span class="customer" style="color: #0070DD"> {customer}</span> 客户联系人 <span style="color: #0070DD">{customerConcatName}({customerConcatPhone})</span>，匹配给组织<span style="color: #0070DD"> {organization}</span> 组织联系人<span style="color: #0070DD"> {organizationConcatName}({organizationConcatPhone})</span>。',
  'pages.customer.customer.myCustomer.modal.hint.two':
    '匹配后该组织用户将查看到该客户的账户余额和订单数据。',
  'pages.customer.customer.myCustomer.modal.hint.three':
    '请仔细核对组织信息和客户信息，确保信息无误后进行操作。',
  'pages.customer.customer.myCustomer.modal.hint.confirm': '确认',

  'pages.customer.customer.myCustomer.modal.field.upload.attachments': '上传附件',
  'pages.customer.customer.push.kingdeeNo': '推送至ERP',
}

export default myCustomer
