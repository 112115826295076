const orderListCustomer = {
  'pages.sale.transaction.order.projectNo': '项目号',
  'pages.sale.transaction.order.projectName': '项目名称',
  'pages.sale.transaction.order.contractStateId': '合同状态',
  'pages.sale.transaction.order.contractStateName': '合同状态',
  'pages.sale.transaction.order.createdTime': '订单创建时间',
  'pages.sale.transaction.order.creatorId': '创建用户ID',
  'pages.sale.transaction.order.creatorName': '创建用户名',
  'pages.sale.transaction.order.customerName': '客户名称',
  'pages.sale.transaction.order.customerRemark': '客户备注',
  'pages.sale.transaction.order.designStateId': '设计状态',
  'pages.sale.transaction.order.designStateName': '设计状态',
  'pages.sale.transaction.order.discountAmount': '商品优惠金额(元)',
  'pages.sale.transaction.order.invoiceStateId': '开票状态',
  'pages.sale.transaction.order.invoiceStateName': '开票状态',
  'pages.sale.transaction.order.paidAmount': '已付金额(元)',
  'pages.sale.transaction.order.paidProportion': '已付比例',
  'pages.sale.transaction.order.paymentStateId': '支付状态',
  'pages.sale.transaction.order.paymentStateName': '支付状态',
  'pages.sale.transaction.order.productAmount': '商品金额(元)',
  'pages.sale.transaction.order.productionStateId': '生产状态',
  'pages.sale.transaction.order.productionStateName': '生产状态',
  'pages.sale.transaction.order.salesOrderNo': '订单编号',
  'pages.sale.transaction.order.settlementId': '结算方式id',
  'pages.sale.transaction.order.settlementName': '结算方式',
  'pages.sale.transaction.order.shipStateId': '发货状态',
  'pages.sale.transaction.order.shipStateName': '发货状态',
  'pages.sale.transaction.order.receiveStateId': '收货状态',
  'pages.sale.transaction.order.receiveStateName': '收货状态',
  'pages.sale.transaction.order.shopOrganizationName': '商家组织',
  'pages.sale.transaction.order.shopRemark': '商务备注',
  'pages.sale.transaction.order.sourceTypeId': '订单来源',
  'pages.sale.transaction.order.sourceTypeName': '订单来源',
  'pages.sale.transaction.order.salesOrderTypeId': '订单类型',
  'pages.sale.transaction.order.salesOrderTypeName': '订单类型',
  'pages.sale.transaction.order.stateId': '订单状态',
  'pages.sale.transaction.order.stateName': '订单状态',
  'pages.sale.transaction.order.kingdeePushStateId': '推送ERP状态',
  'pages.sale.transaction.order.kingdeePushStateName': '推送ERP状态',
  'pages.sale.transaction.order.totalAmount': '订单金额(元)',
  'pages.sale.transaction.order.unpaidAmount': '待付金额(元)',
  'pages.sale.transaction.order.updatedTime': '更新时间',
  'pages.sale.transaction.order.updaterName': '更新用户名',
  'pages.sale.transaction.order.businessRemark': '商务备注',
  'pages.sale.transaction.order.companyAddress': '公司地址',
  'pages.sale.transaction.order.companyName': '公司名称',
  'pages.sale.transaction.order.contractModeId': '合同方式',
  'pages.sale.transaction.order.contractModeName': '合同方式',
  'pages.sale.transaction.order.payableAmount': '应付金额(元)',
  'pages.sale.transaction.order.receiver': '收货人',
  'pages.sale.transaction.order.warehouse': '仓库',
  'pages.sale.transaction.order.warehouseAddress': '仓库地址',
  'pages.sale.transaction.order.freight': '运费金额(元)',
  'pages.sale.transaction.order.salesPerson': '销售负责人',
  'pages.sale.transaction.order.businessPersonPhone': '商务电话',
  'pages.sale.transaction.order.businessPerson': '商务负责人',
  'pages.sale.transaction.order.salesPersonPhone': '销售电话',
  'pages.sale.transaction.order.packageMethod': '包装方式',
  'pages.sale.transaction.order.receiverAddress': '收货地区',
  'pages.sale.transaction.order.receiverDetailAddress': '详细地址',
  'pages.sale.transaction.order.receiverPhone': '联系电话',
  'pages.sale.transaction.order.powderCustomizedAmount': '粉末定制费(元)',
  'pages.sale.transaction.order.powderProcessingAmount': '粉末加工费(元)',
  'pages.sale.transaction.order.combinedCabinetAmount': '并柜服务费(元)',
  'pages.sale.transaction.order.additionalDiscountAmount': '整单优惠金额(元)',
  'pages.sale.transaction.order.fixedAmountCouponAmount': '满减券(元)',
  'pages.sale.transaction.order.freightCouponAmount': '运费券(元)',
  'pages.sale.transaction.order.logisticsModeId': '物流方式',
  'pages.sale.transaction.order.approveStateName': '审核状态',
  'pages.sale.transaction.order.collapse.basicInfo': '基本信息',
  'pages.sale.transaction.order.collapse.contractInfo': '合同信息',
  'pages.sale.transaction.order.collapse.productInfo': '商品信息',
  'pages.sale.transaction.order.collapse.paymentInfo': '付款信息',
  'pages.sale.transaction.order.collapse.pay': '收款',
  'pages.sale.transaction.order.collapse.shippingInfo': '发货信息',
  'pages.sale.transaction.order.collapse.billingInfo': '开票信息',
  'pages.sale.transaction.order.action.pay': '收款',
  'pages.sale.transaction.order.action.cancel': '取消订单',
  'pages.sale.transaction.order.action.delete': '删除订单',
  'pages.sale.transaction.order.action.startDesign': '开始设计',
  'pages.sale.transaction.order.action.completeDesign': '完成设计',
  'pages.sale.transaction.order.action.redesign': '重新设计',
  'pages.sale.transaction.order.action.notifyDesign': '通知设计',
  'pages.sale.transaction.order.action.notifyProduction': '通知排产',
  'pages.sale.transaction.order.action.notifyShip': '通知发货',
  'pages.sale.transaction.order.action.schedulingOrder': '已排产',
  'pages.sale.transaction.order.action.inProduction': '生产中',
  'pages.sale.transaction.order.action.partialWarehoused': '部分入库',
  'pages.sale.transaction.order.action.totalWarehoused': '全部入库',
  'pages.sale.transaction.order.action.partialReceived': '部分收货',
  'pages.sale.transaction.order.action.totalReceived': '全部收货',
  'pages.sale.transaction.order.action.partialShipped': '部分发货',
  'pages.sale.transaction.order.action.totalShipped': '全部发货',
  'pages.sale.transaction.order.action.partialInvoiced': '部分开票',
  'pages.sale.transaction.order.action.totalInvoiced': '全部开票',
  'pages.sale.transaction.order.action.toDraft': '退回草稿',
  'pages.sale.transaction.order.action.pushOrder': '推送订单至ERP',
  'pages.sale.transaction.order.action.pushOrderBom': '推送BOM至ERP',
  'pages.sale.transaction.order.action.changeOrder': '变更订单',
  'pages.sale.transaction.order.action.changeHistory': '变更记录',

  'pages.sale.transaction.order.action.workOrderBom': '生成派工单,BOM表',
  'pages.sale.transaction.order.action.workOrderBom.hint1':
    '当前所选商品明细中，物料 <span style="color: #0099FF">{name}</span> 存在BOM草稿版本，请确认是否按最新审核通过版本生成派工单，BOM表',
  'pages.sale.transaction.order.action.workOrderBom.hint2':
    '当前所选商品明细中，物料 <span style="color: #0099FF">{name}</span> 无可用BOM版本，',
  'pages.sale.transaction.order.action.workOrderBom.hint3':
    '请在商品明细中将草稿版本的BOM审核通过后再生成派工单，BOM表。',

  'pages.sale.transaction.order.modal.generate': '生成',
  'pages.sale.transaction.order.modal.confirm': '确认',
}

export default orderListCustomer
