const role = {
  'pages.platform.user.role.name': '角色名称',
  'pages.platform.user.role.description': '描述',
  'pages.platform.user.role.add.role': '新建角色',
  'pages.platform.user.role.function.permissions': '功能权限',
  'pages.platform.user.role.data.permissions': '数据权限',
  'pages.platform.user.role.data.authorization': '授权字段',
  'pages.platform.user.role.role.information': '角色信息',
  'pages.platform.user.role.role.permission': '角色权限',
  'pages.platform.user.role.reference.role': '参考角色',
  'pages.platform.user.role.view.staff': '查看员工',
  'pages.platform.user.role.staff': '员工',
  'pages.platform.user.role.category': '品类',
  'pages.platform.user.role.organization': '组织架构',
  'pages.platform.user.role.person': '个人',
  'pages.platform.user.role.custom.department': '部门',
  'pages.platform.user.role.category.all': '全部品类',
  'pages.platform.user.role.department.all': '全部部门',
  'pages.platform.user.role..departments': '部门',
  'pages.platform.user.role.defaultRoleType': '来源',
}
export default role
