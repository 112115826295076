const promotion = {
  'pages.sale.price.promotion.purchaseRestriction1':
    '每人每种商品限购{field}件/斤/箱，超出后无法购买',
  'pages.sale.price.promotion.purchaseRestriction2':
    '每人每种商品限购{field}件/斤/箱，超出后可按原价购买',
  'pages.sale.price.promotion.addMpg': '新建MPG',
  'pages.sale.price.promotion.mpgName': 'MPG名称',
  'pages.sale.price.promotion.mpgDesc': 'MPG描述',
  'pages.sale.price.promotion.defaultDiscount': '默认折扣',
  'pages.sale.price.promotion.reviewState': '审核状态',
  'pages.sale.price.promotion.addProduct': '添加产品',
  'pages.sale.price.promotion.add': '添加',
  'pages.sale.price.promotion.discountInfo': '折扣信息',
  'pages.sale.price.promotion.productInfo': '产品信息',
  'pages.sale.price.promotion.confirmAdd': '确认添加',
  'pages.sale.price.promotion.discount': '折扣',
  'pages.sale.price.promotion.basicSet': '基础设置',
  'pages.sale.price.promotion.purchaseRestriction': '限购设置',
  'pages.sale.price.promotion.noLimit': '不限购',
  'pages.sale.price.promotion.limit': '每人每种商品限购',
  'pages.sale.price.promotion.limitRule1': ' 件/斤/箱，超出后无法购买',
  'pages.sale.price.promotion.limitRule2': '件/斤/箱，超出后可按原价购买',
  'pages.sale.price.promotion.promotionDesc': '活动描述',
  'pages.sale.price.promotion.promotionUserRange': '用户范围',
  'pages.sale.price.promotion.promotionProductRange': '产品范围',
  'pages.sale.price.promotion.productRange': '活动产品范围',
  'pages.sale.price.promotion.customerGroup': '客户分群',
  'pages.sale.price.promotion.selectGroup': '选择分群',
  'pages.sale.price.promotion.promotionMpg': '活动MPG',
  'pages.sale.price.promotion.selectMpg': '选择MPG',
  'pages.sale.price.promotion.promotionName': '活动名称',
  'pages.sale.price.promotion.promotionTag': '活动标签',
  'pages.sale.price.promotion.promotionTime': '活动时间',
  'pages.sale.price.promotion.customerRange': '客户范围',
  'pages.sale.price.promotion.partCustomer': '指定范围客户',
  'pages.sale.price.promotion.allCustomer': '全部客户',
  'pages.sale.price.promotion.groupName': '群组名称',
  'pages.sale.price.promotion.groupDesc': '群组描述',
  'pages.sale.price.promotion.customerQuantity': '客户数',
  'pages.sale.price.promotion.quantityDiscount': '量购折扣',
  'pages.sale.price.promotion.quantity': '数量',
  'pages.sale.price.promotion.amount': '金额(元)',
  'pages.sale.price.promotion.above': '以上',
  'pages.sale.price.promotion.preferentialTerms': '优惠条件',
  'pages.sale.price.promotion.discountPart': '特价方式',
  'pages.sale.price.promotion.addPromotion': '新建营销活动',
  'pages.sale.price.promotion.addEmployee': '添加员工',
  'pages.sale.price.promotion.promotionStatus': '活动状态',
  'pages.sale.price.promotion.startTime': '开始时间',
  'pages.sale.price.promotion.endTime': '结束时间',
  'pages.sale.price.promotion.more': '更多',
  'pages.sale.price.promotion.fullYuan': '满元 （金额）',
  'pages.sale.price.promotion.morePiece': '满件（数量）',
  'pages.sale.price.promotion.start': '即将开始',
  'pages.sale.price.promotion.end': '已经结束',
  'pages.sale.price.promotion.ing': '正在进行',
  'pages.sale.price.promotion.partProduct': '指定范围产品',
  'pages.sale.price.promotion.allProduct': '全部产品',
  'pages.sale.price.promotion.preheatTime': '预热时间',

  'pages.sale.serve.serve-setup.trepanning': '开孔服务',
  'pages.sale.serve.serve-setup.quantity': '数量（个）（包含左边，不包含右边）',
  'pages.sale.serve.serve-setup.charge': '收费（元/单柜）',
}

export default promotion
