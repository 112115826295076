const bom = {
  'pages.material.bom.bom-list.field.bomNo': 'BOM 编号',
  'pages.material.bom.bom-list.field.bomVersion': '当前版本',
  'pages.material.bom.bom-list.field.version': '版本',
  'pages.material.bom.bom-list.field.description': '描述',
  'pages.material.bom.bom-list.field.assembleAccessory': '是否安装附件',
  'pages.material.bom.bom-list.field.topMaterial': '物料',
  'pages.material.bom.bom-list.field.materialName': '物料名称',
  'pages.material.bom.bom-list.field.materialNo': '物料编码',
  'pages.material.bom.bom-list.field.name': '名称',
  'pages.material.bom.bom-list.field.position': '位置',
  'pages.material.bom.bom-list.field.bomName': 'BOM名称',
  'pages.material.bom.bom-list.field.releaseStateId': '发布状态',
  'pages.material.bom.bom-list.field.materialAttribute': 'MRP类型',
  'pages.material.bom.bom-list.field.materialType': '物料类型',
  'pages.material.bom.bom-list.field.partTypeName': '部件类型',
  'pages.material.bom.bom-list.field.assembleTypeName': '组装方式',
  'pages.material.bom.bom-list.field.customerProvided': '是否客供',
  'pages.material.bom.bom-list.field.kingdeePushStateName': '推送至ERP状态',
  'pages.material.bom.bom-list.action.createBom': '新建BOM',
  'pages.material.bom.bom-list.drawer.title': '新建 BOM',
  'pages.material.bom.bom-list.drawer.collapse.version': '启用版本',
  'pages.material.bom.bom-list.drawer.collapse.state': '启用状态',
  'pages.material.bom.bom-list.drawer.collapse.approvedTime': '审核时间',
  'pages.material.bom.bom-list.drawer.collapse.switchVersion': '切换版本：',
  'pages.material.bom.bom-list.drawer.collapse.moreVersion': '更多版本',
  'pages.material.bom.bom-list.drawer.collapse.basicInfo': '基本信息',
  'pages.material.bom.bom-list.drawer.collapse.material': '物料',
  'pages.material.bom.bom-list.drawer.collapse.assembleInfo': '部件信息',
  'pages.material.bom.bom-list.drawer.collapse.approveRemark': '审核意见',
  'pages.material.bom.bom-list.drawer.material.field.categoryName': '品类',
  'pages.material.bom.bom-list.drawer.material.field.assembleAccessory': '是否安装附件',
  'pages.material.bom.bom-list.drawer.material.field.brandName': '品牌',
  'pages.material.bom.bom-list.drawer.material.field.seriesName': '系列',
  'pages.material.bom.bom-list.drawer.material.field.bomTypeName': 'BOM类型',
  'pages.material.bom.bom-list.drawer.material.field.materialAttribute': '物料属性',
  'pages.material.bom.bom-list.drawer.material.field.materialNo': '物料编号',
  'pages.material.bom.bom-list.drawer.material.field.materialQuantity': '物料数量',
  'pages.material.bom.bom-list.drawer.material.field.quantity': '数量',
  'pages.material.bom.bom-list.drawer.material.field.materialType': '物料类型',
  'pages.material.bom.bom-list.drawer.material.field.unit': '单位',
  'pages.material.bom.bom-list.drawer.material.field.name': '物料名称',
  'pages.material.bom.bom-list.drawer.material.field.orderNo': '订货号',
  'pages.material.bom.bom-list.drawer.material.action.addSame': '添加同级物料',
  'pages.material.bom.bom-list.drawer.material.action.addSub': '添加子级物料',
  'pages.material.bom.bom-list.drawer.material.action.viewReplaceableMaterials': '查看替换物料',
  'pages.material.bom.bom-list.drawer.material.referenceMaterial': '参考物料',
  'pages.material.bom.bom-list.drawer.material.chooseReferenceTitle': '选择参考物料 BOM',
  'pages.material.bom.bom-list.drawer.material.action.chooseReference': '选择参考',
  'pages.material.bom.bom-list.drawer.material.action.addVersion': '添加版本',
  'pages.material.bom.bom-list.drawer.material.action.addBomVersion': '添加BOM版本',
  'pages.material.bom.bom-list.drawer.material.message.chooseTop': '请先选择物料',
  'pages.material.bom.bom-list.drawer.material.message.existed': '{names}已存在',
  'pages.material.bom.bom-list.drawer.material.message.materialShortNo': '订货代码',
  'pages.material.bom.bom-list.drawer.material.message.specification': '规格',
  'pages.material.bom.bom-list.drawer.material.message.relationStateName': '关联状态',
  'pages.material.bom.drawer.bomComponents.add': '添加子部件',
  'pages.material.bom.drawer.bomComponents.remove': '移除部件',
  'pages.material.bom.bom-list.drawer.material.message.mrpType': 'MRP类型',
  'pages.material.bom.bom-list.drawer.material.message.materialType': '物料类型',
  'pages.material.bom.bom-list.drawer.material.message.bomVersion': 'BOM版本',
  'pages.material.bom.bom-list.drawer.material.message.stateName': '状态',
  'pages.material.bom.bom-list.drawer.material.message.bomTypeName': 'BOM类型',
  'pages.material.bom.bom-list.drawer.material.message.approvalStateName': '审核状态',
  'pages.material.bom.bom-list.drawer.material.message.approverName': '审核人',
  'pages.material.bom.bom-list.drawer.material.message.approvedTime': '审核时间',
  'pages.material.bom.bom-list.drawer.material.message.creatorName': '创建人',
  'pages.material.bom.bom-list.drawer.material.message.createdTime': '创建时间',
  'pages.material.bom.bom-list.drawer.material.message.updaterName': '更新人',
  'pages.material.bom.bom-list.drawer.material.message.updatedTime': '更新时间',
  'pages.material.bom.bom-list.drawer.material.message.approvalRemark': '审核意见',
  'pages.material.bom.bom-list.drawer.material.message.description': '描述',
  'pages.material.bom.bom-list.drawer.material.message.setPosition': '设置位置',
  'pages.material.bom.bom-list.drawer.material.message.position': '位置',
  'pages.material.bom.bom-list.drawer.delete.bom': '删除BOM',
  'pages.material.bom.bom-list.drawer.delete.bom.hint.one': '请确认您要删除这个BOM。',
  'pages.material.bom.bom-list.drawer.delete.bom.hint.two':
    '该BOM删除后，对应物料下将无法查询到该BOM。',
  'pages.material.bom.bom-list.drawer.delete.bom.hint.three': '是否确认删除？',
  'pages.material.bom.bom-list.drawer.delete.bom.number.verify': '请输入正确的数量',
  'pages.material.bom.bom-list.index.batch.operation': '更多',

  'pages.material.bom.bom-list.index.subject': '主体件',
  'pages.material.bom.bom-list.index.accessory': '附件',
  'pages.material.bom.bom-list.index.packing。materials': '包材',

  'pages.material.bom.bom-list.index.install': '总装',
  'pages.material.bom.bom-list.index.subassembly': '部装',
  'pages.material.bom.bom-list.index.preassemble': '预装',

  'pages.material.bom.bom-list.drawer.modal.title': '启用版本',
  'pages.material.bom.bom-list.drawer.modal.hint1': '当前已启用版本为{new}',
  'pages.material.bom.bom-list.drawer.modal.hint2': '如需启用版本{current}，版本{new}将自动停用',

  'pages.material.bom.bom-list.field.copy.bom': '复制BOM',

  'pages.material.bom.bom-list.push.material.by.bom': '推送子部件至ERP',
}

export default bom
