export default {
  'pages.sale.price.manualOrder.companyName': '客户名称',
  'pages.sale.price.manualOrder.createCustomer': '创建新客户',
  'pages.sale.price.manualOrder.custom-service': '定制服务',
  'pages.sale.price.manualOrder.contract': '合同',
  'pages.sale.price.manualOrder.remark': '商务备注',
  'pages.sale.price.manualOrder.gotoSelect': '去选型',
  'pages.sale.price.manualOrder.customerType': '客户型号',
  'pages.sale.price.manualOrder.quantityInfo': '请输入正整数!',
  'pages.sale.price.manualOrder.selectInfo': '请先选择客户！',
  'pages.sale.price.manualOrder.reConfig': '重新配置',
  'pages.sale.price.manualOrder.noRowsText': '暂无商品，请点击右上方按钮添加商品',
  'pages.sale.price.manualOrder.addInvoiceInfo': '请先选择客户！',
}
