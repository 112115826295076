const discount = {
  'pages.sale.price.discount.addMpg': '添加客户专属MPG',
  'pages.sale.price.discount.addDiscount': '添加专属折扣',
  'pages.sale.price.discount.mpgName': 'MPG名称',
  'pages.sale.price.discount.CustomerName': '客户名称',
  'pages.sale.price.discount.defaultDiscount': '默认折扣',
  'pages.sale.price.discount.discount': '折扣',
  'pages.sale.price.discount.customerInfo': '客户信息',
  'pages.sale.price.discount.mpgList': 'MPG列表',
  'pages.sale.price.discount.special': '专属折扣',
  'pages.sale.price.discount.selectMsg': '请选择MPG',
  'pages.sale.price.discount.specialDiscount': '客户专属折扣',
  'pages.sale.price.discount.commerce': '负责商务',
  'pages.sale.price.discount.technology': '负责技术',
  'pages.sale.price.discount.sales': '负责销售',
}
export default discount
