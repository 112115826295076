const logisticsSetup = {
  'pages.sale.transaction.logistics.setup.add.logistics': '新建物流',
  'pages.sale.transaction.logistics.setup.field.name': '仓库',
  'pages.sale.transaction.logistics.setup.field.no': '编号',
  'pages.sale.transaction.logistics.setup.field.typeName': '类型',
  'pages.sale.transaction.logistics.setup.field.district': '省-市-县/区',
  'pages.sale.transaction.logistics.setup.field.detailAddress': '详细地址',
  'pages.sale.transaction.logistics.setup.field.contact': '联系人',
  'pages.sale.transaction.logistics.setup.field.telephone': '联系电话',
  'pages.sale.transaction.logistics.setup.field.warehouseDelivery': '配送区域',
  'pages.sale.transaction.logistics.setup.drawer.search': '搜索',
  'pages.sale.transaction.logistics.setup.drawer.search.basic': '基本信息',
  'pages.sale.transaction.logistics.setup.drawer.freight.setup': '运费设置',
  'pages.sale.transaction.logistics.setup.drawer.field.district': '省份-城市-区县',
  'pages.sale.transaction.logistics.setup.drawer.field.freight': '运费(元/m³)',
  'pages.sale.transaction.logistics.setup.drawer.field.drayage': '短驳费(元/次)',
  'pages.sale.transaction.logistics.setup.drawer.field.short.delivery.cycle': '最短发货周期(天)',
  'pages.sale.transaction.logistics.setup.drawer.field.long.delivery.cycle': '最长发货周期(天)',
  'pages.sale.transaction.logistics.setup.status.finished.product': '成品仓',
  'pages.sale.transaction.logistics.setup.status.line.edge': '线边仓',
  'pages.sale.transaction.logistics.setup.status.raw.material': '原料仓',
  'pages.sale.transaction.logistics.setup.status.raw.Info': '请输入正数!',
}

export default logisticsSetup
