const orderListCustomer = {
  'pages.sale.purchase.order.logisticsModeId': '物流方式',
  'pages.sale.purchase.order.projectNo': '项目号',
  'pages.sale.purchase.order.projectName': '项目名称',
  'pages.sale.purchase.order.contractStateId': '合同状态',
  'pages.sale.purchase.order.contractStateName': '合同状态',
  'pages.sale.purchase.order.createdTime': '创建时间',
  'pages.sale.purchase.order.creatorId': '创建用户ID',
  'pages.sale.purchase.order.creatorName': '创建用户名',
  'pages.sale.purchase.order.customerName': '客户名称',
  'pages.sale.purchase.order.customerRemark': '客户备注',
  'pages.sale.purchase.order.designStateId': '设计状态',
  'pages.sale.purchase.order.designStateName': '设计状态',
  'pages.sale.purchase.order.discountAmount': '商品优惠金额(元)',
  'pages.sale.purchase.order.invoiceStateId': '开票状态',
  'pages.sale.purchase.order.invoiceStateName': '开票状态',
  'pages.sale.purchase.order.paidAmount': '已付金额(元)',
  'pages.sale.purchase.order.paidProportion': '已付比例',
  'pages.sale.purchase.order.paymentStateId': '支付状态',
  'pages.sale.purchase.order.paymentStateName': '支付状态',
  'pages.sale.purchase.order.productAmount': '商品金额(元)',
  'pages.sale.purchase.order.productionStateId': '生产状态',
  'pages.sale.purchase.order.productionStateName': '生产状态',
  'pages.sale.purchase.order.salesOrderNo': '订单编号',
  'pages.sale.purchase.order.settlementId': '结算方式id',
  'pages.sale.purchase.order.settlementName': '结算方式',
  'pages.sale.purchase.order.shipStateId': '发货状态',
  'pages.sale.purchase.order.shipStateName': '发货状态',
  'pages.sale.purchase.order.shopOrganizationName': '供应商',
  'pages.sale.purchase.order.businessRemark': '商务备注',
  'pages.sale.purchase.order.companyAddress': '公司地址',
  'pages.sale.purchase.order.companyName': '公司名称',
  'pages.sale.purchase.order.contractModeId': '合同方式',
  'pages.sale.purchase.order.contractModeName': '合同方式',
  'pages.sale.purchase.order.payableAmount': '应付金额(元)',
  'pages.sale.purchase.order.receiver': '收货人',
  'pages.sale.purchase.order.receiverDetailAddress': '收货地址',
  'pages.sale.purchase.order.receiverPhone': '联系电话',
  'pages.sale.purchase.order.shopRemark': '商户备注信息',
  'pages.sale.purchase.order.sourceTypeId': '订单来源',
  'pages.sale.purchase.order.sourceTypeName': '订单来源',
  'pages.sale.purchase.order.stateId': '订单状态',
  'pages.sale.purchase.order.stateName': '订单状态',
  'pages.sale.purchase.order.totalAmount': '订单金额(元)',
  'pages.sale.purchase.order.unpaidAmount': '待付金额(元)',
  'pages.sale.purchase.order.updatedTime': '更新时间',
  'pages.sale.purchase.order.updaterName': '更新用户名',
  'pages.sale.purchase.order.freight': '运费金额(元)',
  'pages.sale.purchase.order.powderCustomizedAmount': '粉末定制费(元)',
  'pages.sale.purchase.order.powderProcessingAmount': '粉末加工费(元)',
  'pages.sale.purchase.order.combinedCabinetAmount': '并柜服务费(元)',
  'pages.sale.purchase.order.additionalDiscountAmount': '整单优惠金额(元)',
  'pages.sale.purchase.order.fixedAmountCouponAmount': '满减券(元)',
  'pages.sale.purchase.order.freightCouponAmount': '运费券(元)',
  'pages.sale.purchase.order.salesPerson': '销售负责人',
  'pages.sale.purchase.order.salesPersonPhone': '销售电话',
  'pages.sale.purchase.order.businessPerson': '商务负责人',
  'pages.sale.purchase.order.businessPersonPhone': '商务电话',
  'pages.sale.purchase.order.packageMethod': '包装方式',
  'pages.sale.purchase.order.collapse.basicInfo': '基本信息',
  'pages.sale.purchase.order.collapse.contractInfo': '合同信息',
  'pages.sale.purchase.order.collapse.productInfo': '商品信息',
  'pages.sale.purchase.order.collapse.paymentInfo': '付款信息',
  'pages.sale.purchase.order.collapse.shippingInfo': '发货信息',
  'pages.sale.purchase.order.collapse.billingInfo': '开票信息',
  'pages.sale.purchase.order.action.pay': '付款',
  'pages.sale.purchase.order.action.cancel': '取消订单',
}

export default orderListCustomer
