const equipment = {
  'pages.material.process.equipment-type.name': '名称',
  'pages.material.process.equipment-type.addEquipment': '新建设备类型',
  'pages.material.process.equipment-type.editEquipment': '编辑设备类型',
  'pages.material.process.equipment-type.equipment': '设备类型',
  'pages.material.process.equipment-type.number': '编号',
  'pages.material.process.equipment-type.desc': '描述',
  'pages.material.process.equipment-type.cost': '单位成本 (元/天)',
  'pages.material.process.equipment-type.batchDelete': '批量删除',
  'pages.material.process.equipment-type.price': '元/天',
  'pages.material.process.equipment-type.costUnit': '单位成本',

  'pages.material.material..equipment-type.deleteMsg':
    '此操作会删除 {field} {name},  将导致该 {name} 不可用 ',
}

export default equipment
