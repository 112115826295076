const contractTable = {
  'pages.order.components.contract.firstPartyContractNo': '甲方合同号',
  'pages.order.components.contract.secondPartyContractNo': '乙方合同号',
  'pages.order.components.contract.contractModeName': '合同方式',
  'pages.order.components.contract.contractTypeName': '合同类型',
  'pages.order.components.contract.contractTemplateName': '合同模板名称',
  'pages.order.components.contract.stateName': '状态',
  'pages.order.components.contract.firstPartySignatureTime': '甲方签署日期',
  'pages.order.components.contract.secondPartySignatureTime': '乙方签署日期',
  'pages.order.components.contract.signatureTime': '签署日期',
  'pages.order.components.contract.signingContract': '签署合同',
  'pages.order.components.contract.initiateSignContract': '发起签署',
  'pages.order.components.contract.resetSigning': '重置签署',
  'pages.order.components.contract.viewContract': '预览合同',
  'pages.order.components.contract.downloadContract': '下载合同',
  'pages.order.components.contract.deleteContract': '删除合同',
  'pages.order.components.contract.remark': '备注',
  'pages.order.components.contract.uploadContract': '上传合同',
}

export default contractTable
