export default {
  'pages.sale.price.series-setting.series.brandName': '品牌',
  'pages.sale.price.series-setting.series.categoryName': '关联品类',
  'pages.sale.price.series-setting.series.createdTime': '创建时间',
  'pages.sale.price.series-setting.series.creatorName': '创建用户',
  'pages.sale.price.series-setting.series.description': '描述',
  'pages.sale.price.series-setting.series.imageUuid': '展示图片',
  'pages.sale.price.series-setting.series.publishStateName': '启用状态',
  'pages.sale.price.series-setting.series.seriesName': '系列',
  'pages.sale.price.series-setting.series.updatedTime': '更新时间',
  'pages.sale.price.series-setting.series.updaterName': '更新用户',
  'pages.sale.price.series-setting.series.addSeries': '添加系列',
  'pages.sale.price.series-setting.series.editSeries': '编辑系列',
  'pages.sale.price.series-setting.series.removeSeries': '移除系列',
  'pages.sale.price.series-setting.series.removeSeries.hint1': '请确认您要移除这{num}个系列',
  'pages.sale.price.series-setting.series.removeSeries.hint2':
    '本次操作不会影响产品系列的内容，移除后将不会在商城内展示该系列。',
  'pages.sale.price.series-setting.series.removeSeries.hint3':
    '如果移除后想在商城展示该系列，请重新添加系列。',
}
