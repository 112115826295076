const mpg = {
  'pages.sale.price.mpg.addMpg': '新建MPG',
  'pages.sale.price.mpg.addMPG': '添加MPG',
  'pages.sale.price.mpg.mpgName': 'MPG名称',
  'pages.sale.price.mpg.defaultDiscount': '默认折扣',
  'pages.sale.price.mpg.reviewState': '审核状态',
  'pages.sale.price.mpg.addProduct': '添加产品',
  'pages.sale.price.mpg.discountInfo': '折扣信息',
  'pages.sale.price.mpg.productInfo': '产品信息',
  'pages.sale.price.mpg.confirmAdd': '确认添加',
  'pages.sale.price.mpg.discount': '折扣',
  'pages.sale.price.mpg.deleteMsg': '此操作会删除{field}将导致该MPG不可用',
  'pages.material.material.price': '市场价(元)',
  'pages.material.material.addProductModal': '添加商品',
}
export default mpg
