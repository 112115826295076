const routeList = {
  'pages.material.process.route-list.name': '名称',
  'pages.material.process.route-list.number': '编号',
  'pages.material.process.route-list.relate': '关联工序',
  'pages.material.process.route-list.equipment': '设备',
  'pages.material.process.route-list.operator': '工种',
  'pages.material.process.route-list.relateNum': '关联工序编号',
  'pages.material.process.route-list.desc': '描述',
  'pages.material.process.route-list.equipmentTime': '设备时长  (h)',
  'pages.material.process.route-list.operationTime': '操作人员  (h)',
  'pages.material.process.route-list.action.createCraftRoute': '新建工艺路线',
  'pages.material.process.route-list.field.name': '名称',
  'pages.material.process.route-list.field.number': '编号',
  'pages.material.process.route-list.field.description': '描述',
  'pages.material.process.route-list.field.equipmentType': '设备类型',
  'pages.material.process.route-list.field.equipmentDuration': '设备时长(h)',
  'pages.material.process.route-list.field.workType': '工种',
  'pages.material.process.route-list.field.workTypeDuration': '工种时长(h)',
  'pages.material.process.route-list.field.process': '工序',
  'pages.material.process.route-list.field.kingdeeNo': 'ERP工艺路线编号',
  'pages.material.process.route-list.tip.deleteConfirm':
    '确定要删除 {count} 条工艺路线吗？删除后将导致该工艺不可用',
  'pages.material.process.craft.route.drawer.title.basic': '基本信息',
  'pages.material.process.craft.route.drawer.title.material': '关联物料',
  'pages.material.process.craft.route.drawer.title.materialName': '物料名称',
  'pages.material.process.craft.route.drawer.relateNum': '关联工序编号',
  'pages.material.process.craft.route.drawer.action.addRelatedMaterial': '添加关联物料',
  'pages.material.process.craft.route.drawer.processExisted': '工序：{names} 已存在',
  'pages.material.process.craft.route.modal.process': '工序',
  'pages.material.process.craft.route.modal.select.process': '请选择工序',

  'pages.material.process.route-list.state10': '已启用',
  'pages.material.process.route-list.state20': '已停用',
}

export default routeList
