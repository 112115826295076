const contract = {
  'pages.setting.contract.template.name': '合同模板名称',
  'pages.setting.contract.template.relatedCustomerStateName': '是否被客户关联',
  'pages.setting.contract.template.enableStateName': '启用状态',
  'pages.setting.contract.template.defaultStateName': '是否默认',
  'pages.setting.contract.template.add.contract': '新建模板',
  'pages.setting.contract.template.copy.contract': '复制模板',
  'pages.setting.contract.template.option.set.default': '设为默认',
  'pages.setting.contract.template.model.set.default.hint.one':
    '请确认您要将这个合同模板设置为默认。',
  'pages.setting.contract.template.model.set.default.hint.two':
    '您只能将一条合同模板设为默认，修改后其余结算方式将自动修改为否。',
  'pages.setting.contract.template.model.set.default.hint.three':
    '这条合同模板将成为客户的默认合同模板。',

  'pages.setting.contract.template.model.disabled.hint.one':
    '请确认这个合同模板没有关联的客户，您将停用{name}这个合同模板。',
  'pages.setting.contract.template.model.disabled.hint.two':
    '这个合同模板将无法使用，停用的合同模板需要重新启用后才能被客户关联。',

  'pages.setting.contract.template.model.enable.hint.one': '请确认您要启用这个合同模板。',
  'pages.setting.contract.template.model.enable.hint.two':
    '这个合同模板在启用中将无法修改或者删除。',

  'pages.setting.contract.template.model.delete.hint.one':
    '您将删除这个合同模板，请确认这个合同模板没有关联的客户。',
  'pages.setting.contract.template.model.delete.hint.two': '这个合同模板将不会出现您的列表中。',
  'pages.setting.contract.template.model.delete.hint.three':
    '如果您之后想继续使用这个合同模板，通常是停用，而不是删除。',

  'pages.setting.contract.template.drawer.edit.template': '编辑模板',
  'pages.setting.contract.template.drawer.preview.template': '预览模板',

  'pages.setting.contract.template.drawer.title.basic.info': '基本信息',
  'pages.setting.contract.template.drawer.title.current.customer': '当前使用客户',

  'pages.setting.contract.template.drawer.field.name': '客户名称',
  'pages.setting.contract.template.drawer.field.contact': '客户联系人',
  'pages.setting.contract.template.drawer.field.contact.phone': '联系人电话',
  'pages.setting.contract.template.drawer.field.sell': '销售负责人',
  'pages.setting.contract.template.drawer.field.sell.phone': '销售电话',
  'pages.setting.contract.template.drawer.field.business': '商务负责人',
  'pages.setting.contract.template.drawer.field.business.phone': '商务电话',

  'pages.setting.contract.template.type': '类型',
  'pages.setting.contract.template.sales contract': '销售合同',
  'pages.setting.contract.template.change contract': '变更合同',
}

export default contract
