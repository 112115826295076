const productManagement = {
  'pages.sale.price.product.batchAboutMpg': '关联MPG',
  'pages.sale.price.product.batchRemove': '移除',
  'pages.sale.price.product.batchUp': '上架',
  'pages.sale.price.product.batchDown': '下架',
  'pages.sale.price.product.addProduct': '添加产品',
  'pages.sale.price.product.addSaleProduct': '添加可售产品',
  'pages.sale.price.product.confirmAdd': '确认添加',
  'pages.sale.price.product.removeMaterial': '移除物料',
  'pages.sale.price.product.bindMpg': '绑定MPG',
  'pages.sale.price.product.addMpg': '新建MPG',
  'pages.sale.price.product.updatePriceRecord': '调价记录',
  'pages.sale.price.product.priceBatchImport': '价格导入',
  'pages.sale.price.product.confirm': '确认',
  'pages.sale.price.product.upState': '上架状态',
  'pages.sale.price.product.materialNo': '物料编码',
  'pages.sale.price.product.mpgName': 'MPG名称',
  'pages.sale.price.product.defaultDiscount': '默认折扣',
  'pages.sale.price.product.myDiscount': '我的权限折扣',
  'pages.sale.price.product.price': '市场价',
  'pages.sale.price.product.price1': '市场价(元)',
  'pages.sale.price.product.updatePriceApply': '调价申请',
  'pages.sale.price.product.updatePrice': '调价',
  'pages.sale.price.product.lastPrice': '上次价格',
  'pages.sale.price.product.lastPrice1': '上次价格(元)',
  'pages.sale.price.product.up': '上架',
  'pages.sale.price.product.down': '下架',
  'pages.sale.price.product.historyUpdatePrice': '历史调价',
  'pages.sale.price.product.addPromotion': '添加营销活动',
  'pages.sale.price.product.priceDiscount': '价格折扣',
  'pages.sale.price.product.sizeAndWeight': '尺寸与重量',
  'pages.sale.price.product.specificationAttribute': '规格属性',
  'pages.sale.price.product.name': '名称',
  'pages.sale.price.product.productNo': '编码',
  'pages.sale.price.product.categoryName': '品类',
  'pages.sale.price.product.mrp': 'MRP属性',
  'pages.sale.price.product.type': '类型',
  'pages.sale.price.product.reviewState': '审核状态',
  'pages.sale.price.product.brandName': '品牌',
  'pages.sale.price.product.fitSeries': '适用系列',
  'pages.sale.price.product.series': '系列',
  'pages.sale.price.product.weightUnit': '重量单位',
  'pages.sale.price.product.length': '长度',
  'pages.sale.price.product.width': '宽度',
  'pages.sale.price.product.height': '高度',
  'pages.sale.price.product.perimeter': '周长',
  'pages.sale.price.product.weight': '重量',
  'pages.sale.price.product.operator': '操作人',
  'pages.sale.price.product.applyTime': '申请时间',
  'pages.sale.price.product.approvalTime': '审核通过时间',
  'pages.sale.price.product.auditor': '审核人',
  'pages.sale.price.product.more': '更多',
  'pages.sale.price.product.uploadInfo': '请选择正确格式的文件',
  'pages.sale.price.product.uploadFile': '上传文件',
  'pages.sale.price.product.addFile': '添加文件',
  'pages.sale.price.product.exportMsg': '未选择产品',
  'pages.sale.price.product.noUpInfo': '数据中存在无市场价或已上架的产品，无法上架',
  'pages.sale.price.product.noDownInfo': '数据中存在已下架产品，请勿重复下架',
  'pages.sale.price.product.isSaleable': '是否可售',
  'pages.sale.price.product.oneDownInfo': '该产品目前参与活动中，下架后将无法参与活动，是否确认？',
  'pages.sale.price.product.batchDownInfo':
    '数据中存在参与活动中，下架后将无法参与活动，是否确认？',
  'pages.sale.price.product.specification': '规格',
  'pages.sale.price.product.materialShortNo': '订货代码',
  'pages.sale.price.product.mrpTypeName': 'MRP类型',
  'pages.sale.price.product.materialType': '物料类型',
  'pages.sale.price.product.unit': '基本单位',
  'pages.sale.price.product.orderNo': '订货号',
  'pages.sale.price.product.on.shelf': '已上架',
  'pages.sale.price.product.off.shelf': '未上架',
  'pages.sale.price.product.off.uploadDoc': '上传文档',
  'pages.sale.price.product.off.priceView': '价格预览',
  'pages.sale.price.product.off.id': '主键',
  'pages.sale.price.product.coverInfo': '上传新文件会覆盖发当前文档，请确认是否上传?',
  'pages.sale.price.product.import': '导入',
  'pages.sale.price.product.importSuccess': '导入成功',
  'pages.sale.price.product.importFail': '导入异常',
  'pages.sale.price.product.importSuccessInfo': '本次共计划导入共{field}条数据，已经导入成功',
  'pages.sale.price.product.planImport': '本次共计划导入共{allCount}条数据,',
  'pages.sale.price.product.successCount': '    条数据已导入成功,',
  'pages.sale.price.product.failCount': '   条导入异常,  请处理异常数据！',
  'pages.sale.price.product.clickConfirmInfo': ' 点击确认重新提交数据，点击取消忽略异常数据。',
  'pages.sale.price.product.importInfo': ' 请选择正确的模板进行导入！',
  'pages.sale.price.product.inputPriceInfo': '请输入不小于0的数！',

  'pages.sale.price.product.batch.edit': '批量编辑',
  'pages.sale.price.product.update.product': '修改的产品',
  'pages.sale.price.product.accessories.selection': '是否支持配件选型',
  'pages.sale.price.product.business.order': '是否仅商务下单',
}
export default productManagement
