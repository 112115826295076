const menu = {
  'pages.platform.resources.menu.menu': '菜单',
  'pages.platform.resources.menu.name': '菜单名称',
  'pages.platform.resources.menu.last': '上级菜单',
  'pages.platform.resources.menu.ofMenu': '所属菜单',
  'pages.platform.resources.menu.icon': '菜单图标',
  'pages.platform.resources.menu.block': '区块',
  'pages.platform.resources.menu.start': '菜单启用',
  'pages.platform.resources.menu.child': '子菜单类型',
  'pages.platform.resources.menu.note': '备注',
  'pages.platform.resources.menu.normal': '标准菜单',
  'pages.platform.resources.menu.navigation': '页面内导航菜单',
  'pages.platform.resources.menu.add.permissions': '新建权限',
  'pages.platform.resources.menu.addMenu': '新建一级菜单',
  'pages.platform.resources.menu.addChildMenu': '新建子菜单',
  'pages.platform.resources.menu.editMenu': '编辑菜单',
  'pages.platform.resources.menu.deleteMenu': '删除菜单',
  'pages.platform.resources.menu.permissions.name': '权限名称',
  'pages.platform.resources.menu.defaultAuthorization': '默认授权对象',
  'pages.platform.resources.menu.deleteMsg': '此操作会删除{field}将导致该权限不可用',
  'pages.platform.resources.menu.deleteMenuMsg': '此操作会删除{field}将会从菜单列表中移除',
  'pages.platform.resources.menu.permissions.resources': '资源',
  'pages.platform.resources.menu.permissions.field': '字段列表',
  'pages.platform.resources.menu.permissions.basicMsg': '基本信息',
  'pages.platform.resources.menu.permissions.resourcesType': '资源类型',
  'pages.platform.resources.menu.permissions.fieldName': '字段名称',
  'pages.platform.resources.menu.permissions.fieldDesc': '字段描述',
  'pages.platform.resources.menu.permissions.attribute': '属性名称',
  'pages.platform.resources.menu.permissions.isRangeFilter': '是否支持范围过滤',
  'pages.platform.resources.menu.permissions.isAuthorizationRange': '默认授权范围',
  'pages.platform.resources.menu.permissions.isDefaultAuthorization': '是否默认授权',
  'pages.platform.resources.menu.permissions.ofField': '所属字段表',
  'pages.platform.resources.menu.permissions.enumeration': '是否枚举值',
  'pages.platform.resources.menu.permissions.enumerationName': '枚举属性名称',
  'pages.platform.resources.menu.permissions.organization': '组织',
  'pages.platform.resources.menu.permissions.user': '用户',
  'pages.platform.resources.menu.delete.permission': '删除权限',
  'pages.platform.resources.menu.delete.hint.one': '请确认您要删除这个权限。',
  'pages.platform.resources.menu.delete.hint.two': '这个权限将会被彻底删除',
  'pages.platform.resources.menu.delete.hint.three':
    '如果您想暂停使用这个权限，通常是不给组织或用户配置权限，而不是删除。',
  'pages.platform.resources.menu.delete.menu.hint.one': '请确认您要删除这个菜单。',
  'pages.platform.resources.menu.delete.menu.hint.two':
    '这个菜单将会被彻底删除。删除后这个菜单将会在所有相关组织内移除。',
  'pages.platform.resources.menu.delete.menu.hint.three':
    '如果您想暂停使用这个菜单，通常选择不启用，而不是删除。',
}

export default menu
