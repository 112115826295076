const craftClass = {
  'pages.material.process.craftClass-type.name': '名称',
  'pages.material.process.craftClass-type.addWorkType': '新建工种',
  'pages.material.process.craftClass-type.editWorkType': '编辑工种',
  'pages.material.process.craftClass-type.craftClass': '工种',
  'pages.material.process.craftClass-type.number': '编号',
  'pages.material.process.craftClass-type.desc': '描述',
  'pages.material.process.craftClass-type.cost': '单位成本 (元/天)',
  'pages.material.process.craftClass-type.batchDelete': '批量删除',
  'pages.material.process.craftClass-type.price': '元/天',
  'pages.material.process.craftClass-type.costUnit': '单位成本(元/天)',

  'pages.material.material..craftClass-type.deleteMsg':
    '此操作会删除 {field} {name},  将导致该 {name} 不可用 ',
}

export default craftClass
