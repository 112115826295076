const authorityLevel = {
  'pages.sale.price.authority-level.add': '新建价格权限',
  'pages.sale.price.authority-level.aboutStaff': '关联员工',
  'pages.sale.price.authority-level.addStaff': '添加员工',
  'pages.sale.price.authority-level.authorityName': '权限名称',
  'pages.sale.price.authority-level.PriceAuthorityName': '价格权限名称',
  'pages.sale.price.authority-level.authorityLevel': '权限层级',
  'pages.sale.price.authority-level.staff': '员工',
  'pages.sale.price.authority-level.enableState': '启用状态',
  'pages.sale.price.authority-level.disable': '停用',
  'pages.sale.price.authority-level.enable': '启用',
  'pages.sale.price.authority-level.staffName': '员工姓名',
  'pages.sale.price.authority-level.role': '角色',
  'pages.sale.price.authority-level.department': '部门',
  'pages.sale.price.authority-level.numberRange': '请输入 1 到 100 之间的数字',
}
export default authorityLevel
