const workbench = {
  'pages.settings.flow.name': '流程名称',
  'pages.settings.flow.code': '流程类型',
  'pages.settings.flow.select.node': '选择节点',
  'pages.settings.flow.node.name': '节点名称',
  'pages.settings.flow.approve.type': '审核类型',
  'pages.settings.flow.auditor.set': '审核人设置',
  'pages.settings.flow.cc.set': '抄送人设置',
  'pages.settings.flow.creatorName': '创建人',
  'pages.settings.flow.createdTime': '创建时间',
  'pages.settings.flow.updaterName': '更新人',
  'pages.settings.flow.updatedTime': '更新时间',

  'pages.settings.flow.countersign': '加签',
  'pages.settings.flow.countersign.mode': '加签方式',
  'pages.settings.flow.countersign.approver': '加签审批人',
  'pages.settings.flow.countersign.message': '加签留言',
  'pages.settings.flow.confirm.countersign': '确认加签',

  'pages.settings.flow.countersign.hand.over': '转交',
  'pages.settings.flow.countersign.hand.over.person': '转交人',
  'pages.settings.flow.countersign.hand.over.message': '转交留言',
  'pages.settings.flow.countersign.confirm.hand.over': '确认转交',

  'pages.settings.flow.countersign.carbon.copy': '抄送',
  'pages.settings.flow.countersign.carbon.copy.person': '抄送人',
  'pages.settings.flow.countersign.confirm.carbon.copy': '确认抄送',

  'pages.settings.flow.countersign.carbon.cancel': '撤销',
  'pages.settings.flow.countersign.carbon.cancel.approval': '撤销审批',
  'pages.settings.flow.countersign.carbon.cancel.hint.one': '请确认您要撤销这次审批。',
  'pages.settings.flow.countersign.carbon.cancel.hint.two':
    '撤销成功后如需再次审批，需重新发起申请审批流程。',

  'pages.settings.flow.type1': '结算方式',
  'pages.settings.flow.type2': '合同模板',
  'pages.settings.flow.type3': '优惠券新建',
  'pages.settings.flow.type4': '优惠券发放',
  'pages.settings.flow.type5': '充值',
  'pages.settings.flow.type6': '编辑订单价格',
  'pages.settings.flow.type7': '火币充值',
  'pages.settings.flow.type8': '订单变更',

  'pages.settings.flow.modal.hint1': '请确认您要删除这个流程。',
  'pages.settings.flow.modal.hint2': '{name}将会从系统内移除。删除后审核流程需新建。',
  'pages.settings.flow.modal.hint3': '如果您想暂停使用该审核流程，通常是停用，而不是删除。',

  'pages.settings.flow.modal.enable.hint1': '当前流程模型状态为已启用，您不能删除该流程模型。',
  'pages.settings.flow.modal.enable.hint2': '删除操作只对已停用流程模型有效。',
  'pages.settings.flow.modal.enable.hint3': '如果您想删除该流程模型，需要先进行停用操作。',
}
export default workbench
