const organizationMenu = {
  'pages.platform.settings.menu.menu': '菜单',
  'pages.platform.settings.menu.name': '菜单名称',
  'pages.platform.settings.menu.last': '上级菜单',
  'pages.platform.settings.menu.ofMenu': '所属菜单',
  'pages.platform.settings.menu.icon': '菜单图标',
  'pages.platform.settings.menu.block': '区块',
  'pages.platform.settings.menu.start': '菜单启用',
  'pages.platform.settings.menu.child': '子菜单类型',
  'pages.platform.settings.menu.note': '备注',
  'pages.platform.settings.menu.normal': '标准菜单',
  'pages.platform.settings.menu.navigation': '页面内导航菜单',
  'pages.platform.settings.menu.add.permissions': '新建权限',
  'pages.platform.settings.menu.permissions.name': '权限名称',
  'pages.platform.settings.menu.permissions.basicMsg': '基本信息',
  'pages.platform.settings.menu.permissions.role': '角色信息',
  'pages.platform.settings.menu.permissions.roleName': '角色名称',
  'pages.platform.settings.menu.permissions.desc': '描述',
}

export default organizationMenu
