const publicMaterial = {
  'pages.public.material.material.source': '物料来源',
  'pages.public.material.audit.status': '审核状态',
  'pages.public.material.material.source0': '手动创建',
  'pages.public.material.material.source1': '模板导入',
  'pages.public.material.material.import.material': '导入物料',
  'pages.public.material.material.update.material': '更新物料',
  'pages.public.material.material.material.import': '物料导入',
  'pages.public.material.material.Uploading.material': '上传物料',
  'pages.public.material.material.material.preview': '物料预览',
  'pages.public.material.material.device.model': '器件模型',
  'pages.public.material.material.synchronization.hint':
    '所选物料品类不一致或物料不是审核通过状态，公库物料导入至私库需要所有选中的物料都在同一品类下且为审核通过的物料',
  'pages.public.material.material.list.synchronization.hint':
    '所选物料品类不一致，公库物料导入至私库需要所有选中的物料都在同一品类下',
  'pages.public.material.existDeviceModel': 'DFM模型',
  'pages.public.material.existEBModel': 'EB模型',
  'pages.public.material.have.existDeviceModel': '有无DFM模型',
  'pages.public.material.have.existEBModel': '有无EB模型',

  'pages.public.material.have.existCompletedSpecification': '是否规格完整',

  'pages.public.material.batch.approval': '批量审核',

  'pages.public.material.export.template': '导出模板',
  'pages.public.material.export.retrieval.item': '导出检索项',

  'pages.public.material.export.hint.one': '当前导出检索项中包含多个品类，无法导出。',
  'pages.public.material.export.hint.two': '请确认导出的检索项中品类唯一。',
  'pages.public.material.export.hint.three': '请确认品类树是否勾选包含子品类筛选。',

  'pages.public.material.export.hint.four': '该品类下暂无物料数据，无法导出。',

  'pages.public.material.ebModelGradeId': 'EB等级',
  'pages.public.material.dfmModelGradeId': 'DFM等级',
}

export default publicMaterial
