const structure = {
  'pages.material.brand.structure.title.createStructure': '新建产品结构',
  'pages.material.brand.structure.title.copyStructure': '复制产品结构',
  'pages.material.brand.structure.field.structure': '名称',
  'pages.material.brand.structure.field.categoryName': '品类',
  'pages.material.brand.structure.field.name': '名称',
  'pages.material.brand.structure.field.description': '描述',
  'pages.material.brand.structure.drawer.title.basic': '基础信息',
  'pages.material.brand.structure.drawer.filed.audit.status': '审核状态',
  'pages.material.brand.structure.drawer.filed.audit.opinion': '审核意见',
  'pages.material.brand.structure.drawer.filed.approverName': '审核人',
  'pages.material.brand.structure.drawer.filed.approverTime': '审核时间',
  'pages.material.brand.structure.drawer.filed.structureUseName': '结构类型',
  'pages.material.brand.structure.drawer.filed.structureUseName0': '物料创建',
  'pages.material.brand.structure.drawer.filed.structureUseName1': '产品选型',
  'pages.material.brand.structure.drawer.title.assembleInfo': '产品结构',
  'pages.material.brand.structure.drawer.title.componentInfo': '部件信息',
  'pages.material.brand.structure.drawer.title.assemble': '部件',
  'pages.material.brand.structure.drawer.title.setSomeDefaultSpecification': '设置{name}默认规格',
  'pages.material.brand.structure.drawer.title.attachment': '附件',
  'pages.material.brand.structure.drawer.title.wrapper': '包材',
  'pages.material.brand.structure.drawer.action.addSub': '添加部件',
  'pages.material.brand.structure.drawer.action.setDefaultSpecification': '设置规格',
  'pages.material.brand.structure.drawer.action.linkedStructureName': '关联结构',
  'pages.material.brand.structure.drawer.action.position': '位置',
  'pages.material.brand.structure.drawer.action.partType': '部件类型',
  'pages.material.brand.structure.drawer.action.pricingType': '计价方式',
  'pages.material.brand.structure.drawer.action.visibleType': '可见类型',
  'pages.material.brand.structure.drawer.action.assemblyMethod': '组装方式',
  'pages.material.brand.structure.drawer.action.targetPart': '目标部件',
  'pages.material.brand.structure.drawer.action.necessaryPart': '必要部件',
  'pages.material.brand.structure.drawer.action.quantity': '数量',
  'pages.material.brand.structure.drawer.action.defaultSpecification': '默认规格',
  'pages.material.brand.structure.drawer.action.linkedSpecification': '关联规格',
  'pages.material.brand.structure.drawer.action.supportSelectSpecification': '选型规格',
  'pages.material.brand.structure.drawer.action.linkedPart': '关联部件',
  'pages.material.brand.structure.drawer.action.connectRelationship': '关联关系',
  'pages.material.brand.structure.drawer.action.brand': '指定品牌',
  'pages.material.brand.structure.drawer.action.series': '指定系列',
  'pages.material.brand.structure.drawer.action.unreal': '是否虚拟',
  'pages.material.brand.structure.drawer.action.defaulted': '是否默认部件',
  'pages.material.brand.structure.drawer.action.process.route': '工艺路线',
  'pages.material.brand.structure.drawer.action.length': '长(mm)',
  'pages.material.brand.structure.drawer.action.width': '宽(mm)',
  'pages.material.brand.structure.drawer.action.height': '高(mm)',
  'pages.material.brand.structure.drawer.action.unfoldLength': '展开长度(mm)',
  'pages.material.brand.structure.drawer.action.unfoldWidth': '展开宽度(mm)',
  'pages.material.brand.structure.drawer.action.value': '公式',
  'pages.material.brand.structure.drawer.action.default.process.route': '默认工艺路线',
  'pages.material.brand.structure.drawer.action.mrp.type': 'MRP类型',
  'pages.material.brand.structure.drawer.action.applicable.series': '适用系列',
  'pages.material.brand.structure.drawer.action.feed.intake.type': '投料方式',
  'pages.material.brand.structure.drawer.action.material.purpose': '物料用途',
  'pages.material.brand.structure.drawer.action.manufacturing.method': '制造方式',
  'pages.material.brand.structure.drawer.action.structure.type': '结构类型',
  'pages.material.brand.structure.drawer.action.material.type': '物料类型',
  'pages.material.brand.structure.drawer.action.materialNo': '物料编码',
  'pages.material.brand.structure.drawer.action.materialName': '物料名称',
  'pages.material.brand.structure.drawer.action.materialCreateRule': '物料创建条件',
  'pages.material.brand.structure.drawer.action.bomCreateRule': 'BOM创建条件',
  'pages.material.brand.structure.drawer.action.sequence': '排序',

  'pages.material.brand.structure.drawer.map.PositionNameMap0': '-',
  'pages.material.brand.structure.drawer.map.PositionNameMap1': '上',
  'pages.material.brand.structure.drawer.map.PositionNameMap2': '下',
  'pages.material.brand.structure.drawer.map.PositionNameMap3': '前',
  'pages.material.brand.structure.drawer.map.PositionNameMap4': '后',
  'pages.material.brand.structure.drawer.map.PositionNameMap5': '左',
  'pages.material.brand.structure.drawer.map.PositionNameMap6': '右',
  'pages.material.brand.structure.drawer.map.PartTypeMap1': '主体件',
  'pages.material.brand.structure.drawer.map.PartTypeMap2': '附件',
  'pages.material.brand.structure.drawer.map.PartTypeMap3': '包材',
  'pages.material.brand.structure.drawer.map.PricingTypeMap1': '独立计价',
  'pages.material.brand.structure.drawer.map.PricingTypeMap2': '包含在母体件',
  'pages.material.brand.structure.drawer.map.VisibleTypeMap1': '随母体可见',
  'pages.material.brand.structure.drawer.map.VisibleTypeMap2': '不可见',
  'pages.material.brand.structure.drawer.map.VisibleTypeMap3': '随机柜可见',
  'pages.material.brand.structure.drawer.map.AssembleTypeMap1': '总装',
  'pages.material.brand.structure.drawer.map.AssembleTypeMap2': '部装',
  'pages.material.brand.structure.drawer.map.AssembleTypeMap3': '预装',
  'pages.material.brand.structure.drawer.map.ConnectRelationshipMap1': '互斥',
  'pages.material.brand.structure.drawer.map.ConnectRelationshipMap2': '伴生',
  'pages.material.brand.structure.drawer.map.mrpType1': '自制',
  'pages.material.brand.structure.drawer.map.mrpType2': '外采',
  'pages.material.brand.structure.drawer.map.mrpType3': '外协',
  'pages.material.brand.structure.drawer.map.mrpType4': '外包',

  'pages.material.brand.structure.drawer.map.feedIntakeType10': '直接领料',
  'pages.material.brand.structure.drawer.map.feedIntakeType20': '直接倒冲',
  'pages.material.brand.structure.drawer.map.feedIntakeType30': '调拨领料',
  'pages.material.brand.structure.drawer.map.feedIntakeType40': '调拨倒冲',
  'pages.material.brand.structure.drawer.map.feedIntakeType50': '不发料',
  'pages.material.brand.structure.drawer.map.materialPurpose1': '钣金类',
  'pages.material.brand.structure.drawer.map.materialPurpose2': '成套类',
  'pages.material.brand.structure.drawer.map.manufacturingMethod1': '组装',
  'pages.material.brand.structure.drawer.map.manufacturingMethod2': '制造',
  'pages.material.brand.structure.drawer.map.structureType1': '正常',
  'pages.material.brand.structure.drawer.map.structureType2': '虚拟',
  'pages.material.brand.structure.drawer.map.structureType3': '变式',
  'pages.material.brand.structure.drawer.map.materialType1': '原材料',
  'pages.material.brand.structure.drawer.map.materialType2': '半成品',
  'pages.material.brand.structure.drawer.map.materialType3': '成品',
  'pages.material.brand.structure.drawer.map.materialType4': '直接辅料',
  'pages.material.brand.structure.drawer.map.materialType5': '间接辅料',

  'pages.material.brand.structure.drawer.map.synchronization.specification.formula': '同步规格公式',
  'pages.material.brand.structure.drawer.map.hint1':
    '在此处输入物料编码可以查询出指定物料，选择指定物料后，通过产品结构新建BOM和新建物料时将不再创建此部件物料。当MRP类型为外采时，指定物料为必选。',
  'pages.material.brand.structure.drawer.map.hint2':
    '在文本框中输入“true”，BOM创建条件为“是”，该部件在创建BOM的时候为默认部件<br/>在文本框中输入“false”，BOM创建条件为“否”，在快速创建BOM时，该部件生成部件BOM，但不在机柜的标准BOM中；<br/>如果在文本框中输入条件，例如“上级高度==800”，则该BOM需要在上级高度为800时才创建',
  'pages.material.brand.structure.drawer.map.hint3':
    '在文本框中输入“true”，物料创建条件为“是”，将根据部件中配置的关联规格和默认规格创建物料；<br/>在文本框中输入“false”，物料创建条件为“否”，则不创建物料；<br/>如果在文本框中输入条件，例如“上级高度==800”，则上级部件条件满足高度为800时才会创建物料。',

  'pages.material.brand.structure.action.changelog': '变更记录',
  'pages.material.brand.structure.changelog.title': '变更日志',
  'pages.material.brand.structure.changelog.field.role': '角色',
  'pages.material.brand.structure.changelog.field.submitTime': '提交时间',
  'pages.material.brand.structure.changelog.field.reviewer': '审核人',
  'pages.material.brand.structure.changelog.field.reviewResult': '审核结果',
  'pages.material.brand.structure.changelog.field.passedTime': '通过时间',
  'pages.material.brand.structure.drawer.modal.set.specification': '设置规格',
  'pages.material.brand.structure.drawer.modal.set.size': '设置尺寸',
  'pages.material.brand.structure.drawer.modal.specification.name': '规格名称',
  'pages.material.brand.structure.drawer.modal.specification.required': '是否必填',
  'pages.material.brand.structure.drawer.modal.specification.type': '规格类型',
  'pages.material.brand.structure.drawer.modal.default.specification': '规格值',
  'pages.material.brand.structure.drawer.modal.associated.specifications': '关联规格',
  'pages.material.brand.structure.drawer.modal.default.specifications': '默认规格',
  'pages.material.brand.structure.drawer.modal.audit.reason': '审核意见',

  'pages.material.brand.structure.drawer.modal.delete.reason': '删除系列',
  'pages.material.brand.structure.drawer.modal.delete.hint.one': '请确认您要删除这个产品结构',
  'pages.material.brand.structure.drawer.modal.delete.hint.two':
    '删除该系列后，将无法恢复，是否确认？',
}

export default structure
