const publicSeries = {
  'pages.public.material.material.source': '物料来源',
  'pages.public.material.audit.status': '审核状态',
  'pages.public.material.material.source0': '手动创建',
  'pages.public.material.material.source1': '模板引入',
  'pages.public.material.material.source2': '导入',
  'pages.public.material.material.source3': '公库同步',
  'pages.public.material.material.source4': '选型',
  'pages.public.material.material.save.add.series': '保存并新建系列',
}

export default publicSeries
