const process = {
  'pages.material.process.process.action.create': '新建工序',
  'pages.material.process.process.field.name': '名称',
  'pages.material.process.process.field.processNo': '编号',
  'pages.material.process.process.field.description': '描述',
  'pages.material.process.process.field.equipmentName': '设备类型',
  'pages.material.process.process.field.workTypeName': '工种',
  'pages.material.process.process.drawer.title': '新建工序',
  'pages.material.process.process.drawer.basicInfo': '基本信息',
  'pages.material.process.process.drawer.equipmentQuantity': '请输入设备数量：',
  'pages.material.process.process.drawer.workTypeQuantity': '请输入工种数量：',
  'pages.material.process.process.tip.deleteConfirm':
    '确定要删除 {count} 条工序吗？删除后将导致该工序不可用',
}

export default process
