export default {
  'pages.sale.price.coupon.record': '发放记录',
  'pages.sale.price.coupon.man-time': '发放人次',
  'pages.sale.price.coupon.createCoupon': '创建优惠券',
  'pages.sale.price.coupon.couponName': '优惠券名称',
  'pages.sale.price.coupon.type': '类型',
  'pages.sale.price.coupon.type1': '满减券',
  'pages.sale.price.coupon.type2': '运费券',
  'pages.sale.price.coupon.consumeMpgInfo': '适用MPG',
  'pages.sale.price.coupon.fixedAmount': '面值(元)',
  'pages.sale.price.coupon.fixed': '面值',
  'pages.sale.price.coupon.pushCount': '发放次数',
  'pages.sale.price.coupon.push': '发放',
  'pages.sale.price.coupon.deleteModalContent': `<p>请确认您要删除优惠券。</p>
  <p>删除该优惠券后将不可恢复。</p>
  <p>如配置错误，请重新编辑；如已进入审核流程，请删除后重新创建。</p>`,
  'pages.sale.price.coupon.deleteCoupon': '删除优惠券',
  'pages.sale.price.coupon.fixedAmountInfo': '面额信息',
  'pages.sale.price.coupon.condition': '使用条件',
  'pages.sale.price.coupon.condition1': '活动商品满',
  'pages.sale.price.coupon.discountMpg': '优惠MPG',
  'pages.sale.price.coupon.productRange': '产品范围',
  'pages.sale.price.coupon.discountRules': '活动规则',
  'pages.sale.price.coupon.reviewProgress': '审核进度',
  'pages.sale.price.coupon.applyReason': '发起理由',
  'pages.sale.price.coupon.applyReason1': '请输入发起理由',
  'pages.sale.price.coupon.createReason': '创建优惠券理由',
  'pages.sale.price.coupon.confirmCreate': '确认创建',
  'pages.sale.price.coupon.saveCraft': '保存草稿',
  'pages.sale.price.coupon.name': '名称',
  'pages.sale.price.coupon.useValidity': '使用有效期',
  'pages.sale.price.coupon.desc': '说明',
  'pages.sale.price.coupon.superpositionRules': '叠加规则',
  'pages.sale.price.coupon.superpositionRules1': '无叠加使用限制',
  'pages.sale.price.coupon.superpositionRules2': '禁止与全部活动叠加使用',
  'pages.sale.price.coupon.selectMpgInfo': '请选择MPG！',
  'pages.sale.price.coupon.selectPushCustomer': '选择发放客户',
  'pages.sale.price.coupon.pushTime': '发放时间',
  'pages.sale.price.coupon.pusher': '发放人',
  'pages.sale.price.coupon.pushState': '发放审核状态',
  'pages.sale.price.coupon.viewPushDetail': '查看优惠券发放详情',
  'pages.sale.price.coupon.reduce': '减',
  'pages.sale.price.coupon.couponSelect': '优惠券选择',
  'pages.sale.price.coupon.available': '可使用',
  'pages.sale.price.coupon.unavailable': '不可用',
  'pages.sale.price.coupon.open': '展开',
  'pages.sale.price.coupon.close': '收起',
  'pages.sale.price.coupon.grantReason': '发放理由',
  'pages.sale.price.coupon.applyReason2': '请输入发放理由',
  'pages.sale.price.coupon.upload.attachments': '上传附件',
}
