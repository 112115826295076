const modal = {
  'pages.material.components.replaceable-materials-modal.title': '替代物料',
  'pages.material.components.replaceable-materials-modal.field.name': '物料名称',
  'pages.material.components.replaceable-materials-modal.field.materialNo': '物料编号',
  'pages.material.components.replaceable-materials-modal.field.categoryName': '物料分类',
  'pages.material.components.replaceable-materials-modal.field.mrpTypeName': '物料属性',
  'pages.material.components.replaceable-materials-modal.field.materialTypeName': '物料类型',
  'pages.material.components.replaceable-materials-modal.field.materialUnit': '单位',
  'pages.material.components.replaceable-materials-modal.field.brandName': '品牌',
  'pages.material.components.replaceable-materials-modal.field.seriesName': '系列',
}

export default modal
