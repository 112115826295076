const warehouseSetup = {
  'pages.sale.transaction.warehouse.setup.add.warehouse': '新建仓库',
  'pages.sale.transaction.warehouse.setup.field.name': '名称',
  'pages.sale.transaction.warehouse.setup.field.no': '编号',
  'pages.sale.transaction.warehouse.setup.field.typeName': '类型',
  'pages.sale.transaction.warehouse.setup.field.district': '省-市-县/区',
  'pages.sale.transaction.warehouse.setup.field.warehouse.enable.state': '仓库启用状态',
  'pages.sale.transaction.warehouse.setup.field.detailAddress': '详细地址',
  'pages.sale.transaction.warehouse.setup.field.contact': '联系人',
  'pages.sale.transaction.warehouse.setup.field.telephone': '联系电话',
  'pages.sale.transaction.warehouse.setup.status.finished.product': '成品仓',
  'pages.sale.transaction.warehouse.setup.status.line.edge': '线边仓',
  'pages.sale.transaction.warehouse.setup.status.raw.material': '原料仓',
}

export default warehouseSetup
