const settlementType = {
  'pages.settings.organization.settlement-type.name': '名称',
  'pages.settings.organization.settlement-type.customerCount': '是否被客户关联',
  'pages.settings.organization.settlement-type.isDefault': '是否默认',
  'pages.settings.organization.settlement-type.addSettlementType': '新增结算方式',
  'pages.settings.organization.settlement-type.setDefault': '设为默认',
  'pages.settings.organization.settlement-type.settlementNodeSet': '结算节点',
  'pages.settings.organization.settlement-type.nowUser': '使用客户',
  'pages.settings.organization.settlement-type.typeName': '结算节点名称',
  'pages.settings.organization.settlement-type.settlementPeriod': '结算周期',
  'pages.settings.organization.settlement-type.paymentRatio': '单节点结算比例',
  'pages.settings.organization.settlement-type.lastAllRatio': '累计结算比例',
  'pages.settings.organization.settlement-type.customerName': '客户名称',
  'pages.settings.organization.settlement-type.customerContactPerson': '客户联系人',
  'pages.settings.organization.settlement-type.customerContactPersonPhoneNumber': '客户联系电话',
  'pages.settings.organization.settlement-type.salesPersonName': '销售负责人',
  'pages.settings.organization.settlement-type.salesPhoneNumber': '销售电话',
  'pages.settings.organization.settlement-type.businessPersonName': '商务负责人',
  'pages.settings.organization.settlement-type.businessPhoneNumber': '商务电话',
  'pages.settings.organization.settlement-type.know': '我知道了',
  'pages.settings.organization.settlement-type.setInfo':
    '如果您想保存目前配置，请调整单个结算节点比例，使累计结算比例为 100% 。',
  'pages.settings.organization.settlement-type.nowProportion': '当前累计结算比例为',
  'pages.settings.organization.settlement-type.saveNodeSet': '保存节点配置',
  'pages.settings.organization.settlement-type.higher': '高于100%',
  'pages.settings.organization.settlement-type.under': '低于100%',
  'pages.settings.organization.settlement-type.day': '天',
  'pages.settings.organization.settlement-type.before': '合同签订后',
  'pages.settings.organization.settlement-type.beforeDeliver': '发货前',
  'pages.settings.organization.settlement-type.afterReceive': '收货后',
  'pages.settings.organization.settlement-type.afterReceipt': '收票后',
  'pages.settings.organization.settlement-type.disableInfo1':
    '请确认这个结算方式没有关联的客户，您将停用这个结算方式。',
  'pages.settings.organization.settlement-type.disableInfo2':
    '这个结算方式将无法使用，停用的结算方式需要重新启用后才能被客户关联。',
  'pages.settings.organization.settlement-type.defaultInfo1':
    '请确认您要将这条结算方式设置为默认结算方式。',
  'pages.settings.organization.settlement-type.defaultInfo2':
    '您只能将一条结算方式设为默认，修改后其余结算方式将自动修改为否。',
  'pages.settings.organization.settlement-type.defaultInfo3':
    '这条结算方式将成为客户的默认结算方式。',
  'pages.settings.organization.settlement-type.enableInfo1': '请确认您要启用这个结算方式。',
  'pages.settings.organization.settlement-type.enableInfo2':
    '这个结算方式在启用中将无法修改或者删除。',
  'pages.settings.organization.settlement-type.deleteInfo1':
    '请确认这个结算方式没有关联的客户，您将删除这个结算方式。',
  'pages.settings.organization.settlement-type.deleteInfo2': '这个结算方式将不会出现您的列表中。',
  'pages.settings.organization.settlement-type.deleteInfo3':
    '如果您之后想继续使用这个结算方式，通常是停用，而不是删除。',
  'pages.settings.organization.settlement-type.contractInfo1':
    '请确认您要将这个合同模板设置为默认。',
  'pages.settings.organization.settlement-type.contractInfo2':
    '您只能将一条合同模板设为默认，修改后其余合同模板将自动修改为否。',
  'pages.settings.organization.settlement-type.contractInfo3':
    '这条合同模板将成为客户的默认合同模板。',
  'pages.settings.organization.settlement-type.orderCancellationTime': '订单取消时间',
  'pages.settings.organization.settlement-type.cabinetManufacturingTime': '图纸确认周期',
  'pages.settings.organization.settlement-type.generalType': '商城通用结算方式',
  'pages.settings.organization.settlement-type.generalContract': '商城通用合同模板',
  'pages.settings.organization.settlement-type.changeContract': '商城变更合同模板',
  'pages.settings.organization.settlement-type.setSettlement': '设置专属结算方式',
  'pages.settings.organization.settlement-type.setContract': '设置商城通用合同',
  'pages.settings.organization.settlement-type.selectSettlement': '选择结算方式',
  'pages.settings.organization.settlement-type.selectContract': '选择专属合同',
  'pages.settings.organization.settlement-type.preview': '预览',
  'pages.settings.organization.settlement-type.manufacturingCycle': '机柜制造周期',
  'pages.settings.organization.settlement-type.settlementTypeName': '结算方式名称',
  'pages.settings.organization.settlement-type.descriptionInfo': '描述信息',
  'pages.settings.organization.settlement-type.changeSalesContract': '选择变更模板',

  'pages.settings.organization.settlement-type.changeSalesContractInfo1':
    '请确认您要将这个变更合同模板设置为默认。',
  'pages.settings.organization.settlement-type.changeSalesContractInfo2':
    '您只能将一条合同模板设为变更合同，修改后其余合同模板将自动修改为否。',
  'pages.settings.organization.settlement-type.changeSalesContractInfo3':
    '这条合同模板将成为客户的变更合同模板。',
}

export default settlementType
