const settlement = {
  'pages.sale.transaction.order-settlement.type': '共{field}件',
  'pages.sale.transaction.order-settlement.autoContract': '甲方合同号',
  'pages.sale.transaction.order-settlement.selectContract': '选填，请输入甲方合同号',
  'pages.sale.transaction.order-settlement.autoContractInfo':
    '非必填，如果买家需要维护合同号，请填写甲方合同号，该甲方合同号会写入合同中',
  'pages.sale.transaction.order-settlement.address': '收货地址',
  'pages.sale.transaction.order-settlement.defaultAddress': '默认地址',
  'pages.sale.transaction.order-settlement.setDefaultAddress': '设为默认地址',
  'pages.sale.transaction.order-settlement.moreAddress': '查看更多地址',
  'pages.sale.transaction.order-settlement.putAddress': '收起地址',
  'pages.sale.transaction.order-settlement.productInfo': '商品信息',
  'pages.sale.transaction.order-settlement.invoiceInfo': '发票信息',
  'pages.sale.transaction.order-settlement.specialInvoice': '增值税专用发票',
  'pages.sale.transaction.order-settlement.ordinaryInvoice': '增值税普通发票',
  'pages.sale.transaction.order-settlement.noInvoice': '暂不开票',
  'pages.sale.transaction.order-settlement.name': '名称',
  'pages.sale.transaction.order-settlement.unifiedSocialCreditCode': '纳税人识别号',
  'pages.sale.transaction.order-settlement.bankAndUsername': '开户行及账号',
  'pages.sale.transaction.order-settlement.addressAndPhone': '地址、电话',
  'pages.sale.transaction.order-settlement.addInvoiceData': '新增发票信息',
  'pages.sale.transaction.order-settlement.invoiceAddress': '收票地址',
  'pages.sale.transaction.order-settlement.isSameAddress': '同收货地址',
  'pages.sale.transaction.order-settlement.freight': '运费(元)',
  'pages.sale.transaction.order-settlement.freight1': '运费',
  'pages.sale.transaction.order-settlement.promotionPrice': '优惠金额(元)',
  'pages.sale.transaction.order-settlement.shouldPay': '订单金额总计(元)',
  'pages.sale.transaction.order-manual.shouldPay': '优惠后金额总计(元)',
  'pages.sale.transaction.order-settlement.confirmOrder': '提交订单',
  'pages.sale.transaction.order-settlement.addAddress': '新增收货地址',
  'pages.sale.transaction.order-settlement.payPage': '支付页面',
  'pages.sale.transaction.order-settlement.productTotal': '商品金额(元)',
  'pages.sale.transaction.order-settlement.contractType': '合同类型',
  'pages.sale.transaction.order-settlement.contractType1': '电子合同',
  'pages.sale.transaction.order-settlement.contractType2': '纸质合同',
  'pages.sale.transaction.order-settlement.electronInfo1':
    '1.下单时预览合同模板内容，如果对合同有疑问可以在提交订单后联系商务咨询（官网右侧浮窗中扫描二维码名片添加）',
  'pages.sale.transaction.order-settlement.electronInfo2':
    '2.电子合同签署为e签宝第三方服务线上签署，没有注册账号的企业请提交订单后由法人前往e签宝进行注册',
  'pages.sale.transaction.order-settlement.electronInfo3':
    '3.提交订单后，在机柜采购单页预览合同，线上签署合同由商务发起签署，发起后买家可以通过接收手机短信链接或者网页登录账号签署，甲乙双方都签署完成且结算满足金额后合同生效',

  'pages.sale.transaction.order-settlement.paperInfo1':
    ' 1.下单时预览合同模板内容，如果对合同有疑问可以在提交订单后联系商务咨询（官网右侧浮窗中扫描二维码名片添加）',
  'pages.sale.transaction.order-settlement.paperInfo2':
    '2.提交订单后线下签署合同，由商务负责人打印纸质合同以后邮寄进行盖章签署',
  'pages.sale.transaction.order-settlement.paperInfo3':
    '3.双方盖章签署过后，由商务负责人扫描合同文件，生成1个pdf文件并且在订单中上传合同文件，商务负责人确定合同签署状态，甲乙双方签署且结算满足金额后合同生效',
  'pages.sale.transaction.order-settlement.previewContract': '预览合同',
  'pages.sale.transaction.order-settlement.reviewSettlementInfo': '查看结算说明',
  'pages.sale.transaction.order-settlement.orderRemark': '订单备注',
  'pages.sale.transaction.order-settlement.materialShortNo': '订货代码',
  'pages.sale.transaction.order-settlement.settlementType': '结算方式',
  'pages.sale.transaction.order-settlement.settlementDesc': '订单结算说明',
  'pages.sale.transaction.order-settlement.settlementInfo1':
    '该订单按照以下的结算方式进行结算，付款周期内需要支付金额达到结算金额，否则可能会',
  'pages.sale.transaction.order-settlement.settlementInfo2': '影响合同生效或者发货进度',
  'pages.sale.transaction.order-settlement.packingType': '包装方式',
  'pages.sale.transaction.order-settlement.invoiceType1': '增值税专用发票',
  'pages.sale.transaction.order-settlement.invoiceType2': '增值税普通发票',
  'pages.sale.transaction.order-settlement.invoiceType3': '暂不开票',
  'pages.sale.transaction.order-settlement.confirmInfo1':
    'e签宝线上签署使用签署人手机号认证，请核对签署人和联系电话',
  'pages.sale.transaction.order-settlement.confirmInfo2': '1.甲方企业法人在e签宝进行企业认证',
  'pages.sale.transaction.order-settlement.confirmInfo3': '2.甲方签署人使用该手机号在e签宝注册账户',
  'pages.sale.transaction.order-settlement.confirmInfo4': '3.企业法人在e签宝给甲方签署人授签署权',
  'pages.sale.transaction.order-settlement.partAOrgName': '甲方名称',
  'pages.sale.transaction.order-settlement.partBOrgName': '乙方名称',
  'pages.sale.transaction.order-settlement.partAPsn': '甲方签署人',
  'pages.sale.transaction.order-settlement.partBPsn': '乙方签署人',
  'pages.sale.transaction.order-settlement.phone': '联系电话',
  'pages.sale.transaction.order-settlement.confirmContract': '确认合同签署信息',
  'pages.sale.transaction.order-settlement.noFreightInfo1':
    '您当前填写的收货地址暂未开通配送服务，将导致无法下单。',
  'pages.sale.transaction.order-settlement.noFreightInfo2':
    '请联系商务将地区配送服务开通后进行订单提交操作。',
  'pages.sale.transaction.order-settlement.noFreightInfo3':
    '(屏幕右侧浮窗中扫描商务二维码名片添加沟通反馈)',
  'pages.sale.transaction.order-settlement.noFreightTitle': '无法配送提示',
  'pages.sale.transaction.order-settlement.perfectInfo1':
    '您在组织中的名字包含特殊字符，请填写真实姓名',
  'pages.sale.transaction.order-settlement.perfectInfo2':
    '您所在的组织的联系人名字中包含特殊字符，请填写真实姓名',
  'pages.sale.transaction.order-settlement.perfectInfo3':
    '您所在的组织公司地址不能为空，请填写公司地址',
}

export default settlement
