const brand = {
  'pages.material.brand.brand': '品牌',
  'pages.material.brand.addBrand': '新建品牌',
  'pages.material.brand.editBrand': '编辑品牌',
  'pages.material.brand.title': '品牌管理',
  'pages.material.brand.field.name': '公司长名',
  'pages.material.brand.field.shortName': '短名',
  'pages.material.brand.field.abbreviation': '简称',
  'pages.material.brand.field.address': '地址',
  'pages.material.brand.field.series': '系列',
  'pages.material.brand.field.code': '编号',
  'pages.material.brand.field.tel': '电话',
  'pages.material.brand.field.country': '国家',
  'pages.material.brand.action.manageSeries': '系列管理',
  'pages.material.brandSeries.title': '产品系列列表',
  'pages.material.brandSeries.field.seriesProductName': '系列/产品结构名称',
  'pages.material.brandSeries.field.brand': '品牌',
  'pages.material.brandSeries.field.reviewState': '审核状态',
  'pages.material.brandSeries.field.publishState': '发布状态',
  'pages.material.brandSeries.field.description': '系列描述',
  'pages.material.brandSeries.action.create': '新建系列',
  'pages.material.brandSeries.action.createProduct': '新建产品结构',
  'pages.material.brand.structure': '产品结构',
  'pages.material.brand.structure.filed.name': '名称',
  'pages.material.brand.structure.filed.series': '所属系列',
  'pages.material.brand.structure.filed.structureDesc': '描述',
  'pages.material.brand.deleteMsg': '此操作会删除 {field} {name},  将导致该 {name} 不可用 ',

  'pages.material.brand.delete.brand': '删除品牌',
  'pages.material.brand.delete.hint1': '请确认您要删除这个品牌。',
  'pages.material.brand.delete.hint2': '这个品牌将会从品牌管理内移除。',
  'pages.material.brand.delete.hint3': '删除后该品牌关联的产品系列都会被删除。',
}

export default brand
