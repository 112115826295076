const customerSegmentation = {
  'pages.customer.customer.customer.segmentation.field.group': '客户分群',
  'pages.customer.customer.customer.segmentation.field.name': '名称',
  'pages.customer.customer.customer.segmentation.field.customer.count': '客户数',
  'pages.customer.customer.customer.segmentation.field.add.customer.group': '新建分群',
  'pages.customer.customer.customer.segmentation.field.cluster.name': '分群名称',
  'pages.customer.customer.customer.segmentation.field.customer.list': '客户列表',
  'pages.customer.customer.customer.segmentation.field.customer.name': '客户名称',
  'pages.customer.customer.customer.segmentation.field.customer.concat.person': '客户联系人',
  'pages.customer.customer.customer.segmentation.field.customer.phone': '联系人电话',
  'pages.customer.customer.customer.segmentation.field.sale.director': '销售负责人',
  'pages.customer.customer.customer.segmentation.field.sale.phone': '销售电话',
  'pages.customer.customer.customer.segmentation.field.business.contact.person': '商务负责人',
  'pages.customer.customer.customer.segmentation.field.business.phone': '商务电话',
  'pages.customer.customer.customer.segmentation.field.technology.contact.person': '技术负责人',
  'pages.customer.customer.customer.segmentation.field.technology.phone': '技术电话',
  'pages.customer.customer.customer.segmentation.field.authentication': '主体认证',
  'pages.customer.customer.customer.segmentation.basic': '基本信息',
  'pages.customer.customer.customer.segmentation.repetition.hint': '重复客户,请修改',
  'pages.customer.customer.customer.segmentation.batch.add.customer': '添加客户',
  'pages.customer.customer.customer.segmentation.add.customer': '添加客户',
  'pages.customer.customer.customer.segmentation.show.Added': '显示已添加',
  'pages.customer.customer.customer.segmentation.field.linkedOrganizationName': '关联组织名称',
  'pages.customer.customer.customer.segmentation.field.fax': '传真',
}
export default customerSegmentation
