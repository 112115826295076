const series = {
  'pages.material.brand.series.field.name': '名称',
  'pages.material.brand.series.field.structureName': '产品结构',
  'pages.material.brand.series.field.publishStateName': '启用状态',
  'pages.material.brand.series.field.brandName': '品牌',
  'pages.material.brand.series.field.description': '系列描述',
  'pages.material.brand.series.field.structureDescription': '产品结构描述',
  'pages.material.brand.series.field.argClassProtect': '防护等级',
  'pages.material.brand.series.field.argShock': '抗冲击等级',
  'pages.material.brand.series.field.argSaltFog': '符合标准',
  'pages.material.brand.series.field.argCe': '认证',
  'pages.material.brand.series.action.create': '新建系列',
  'pages.material.brand.series.action.changelog': '变更记录',
  'pages.material.brand.series.drawer.title.basic': '基础信息',
  'pages.material.brand.series.drawer.title.cert': '认证',
  'pages.material.brand.series.drawer.title.standardConfiguration': '标准配置',
  'pages.material.brand.series.drawer.title.structure': '产品结构',
  'pages.material.brand.series.drawer.title.componentInfo': '部件信息',
  'pages.material.brand.series.drawer.title.attachment': '附件',
  'pages.material.brand.series.drawer.title.wrapper': '包材',
  'pages.material.brand.series.drawer.title.defaultSize': '默认规格',
  'pages.material.brand.series.drawer.field.structureCategory': '部件名称',
  'pages.material.brand.series.drawer.field.attachmentCategory': '附件品类名称',
  'pages.material.brand.series.drawer.field.wrapperCategory': '包材品类名称',
  'pages.material.brand.series.drawer.field.isRequired': '是否必选',
  'pages.material.brand.series.drawer.field.maxQuantity': '最大数量',
  'pages.material.brand.series.drawer.field.unit': '单位',
  'pages.material.brand.series.drawer.state.enabled': '启用',
  'pages.material.brand.series.drawer.state.disabled': '停用',
  'pages.material.brand.series.drawer.action.setDefaultSize': '设置默认规格',
  'pages.material.brand.series.drawer.attribute.cabinetTip':
    '设置柜体规格是否启用，在选型时，客户可以通过输入未启用设置的规格并过滤设置启用的默认规格进行物料的快速筛选。（如设置未启用高、宽、长，客户可在选型时选择高、宽、长规格数值，并过滤设置启用的默认规格值进行机柜快速选型）',
  'pages.material.brand.series.drawer.targetPartRequiredTip': '部装方式需要选择目标部件',
  'pages.material.brand.series.changelog.title': '变更日志',
  'pages.material.brand.series.changelog.field.role': '角色',
  'pages.material.brand.series.changelog.field.submitTime': '提交时间',
  'pages.material.brand.series.changelog.field.reviewer': '审核人',
  'pages.material.brand.series.changelog.field.reviewResult': '审核结果',
  'pages.material.brand.series.changelog.field.passedTime': '通过时间',

  'pages.material.brand.series.drawer.modal.delete.reason': '删除系列',
  'pages.material.brand.series.drawer.modal.delete.hint.one': '请确认您要删除这个产品系列',
  'pages.material.brand.series.drawer.modal.delete.hint.two': '这个系列将会从产品系列内移除。',
  'pages.material.brand.series.drawer.modal.delete.hint.three':
    '如果您系列信息填写错误，可以在详情页内修改',
}

export default series
