const modal = {
  'pages.material.components.process-selector-modal.title': '选择工序',
  'pages.material.components.process-selector-modal.field.materialName': '物料名称',
  'pages.material.components.process-selector-modal.field.materialNo': '物料编号',
  'pages.material.components.process-selector-modal.field.materialNos': '物料编码',
  'pages.material.components.process-selector-modal.field.orderNo': '订货号',
  'pages.material.components.process-selector-modal.field.category': '品类',
  'pages.material.components.process-selector-modal.field.brand': '品牌',
}

export default modal
