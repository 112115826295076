const category = {
  'pages.material.material.categoryAtt': '属性',
  'pages.material.material.categoryAtt.name': '属性名称',
  'pages.material.material.categoryAtt.categoryName': '品类名称',
  'pages.material.material.categoryAtt.copyCategory': '复制品类',
  'pages.material.material.categoryAtt.ofCategory': '所属品类',
  'pages.material.material.categoryAtt.addRoot': '新建一级品类',
  'pages.material.material.categoryAtt.addChild': '新建子品类',
  'pages.material.material.categoryAtt.editCategory': '编辑品类',
  'pages.material.material.categoryAtt.specification': '编辑规格属性',
  'pages.material.material.categoryAtt.deleteCategory': '删除品类',
  'pages.material.material.categoryAtt.category': '品类',
  'pages.material.material.categoryAtt.isRequired': '是否必填',
  'pages.material.material.categoryAtt.defaultValue': '默认值',
  'pages.material.material.categoryAtt.inputTypeId': '表单方式',
  'pages.material.material.categoryAtt.connectionType': '连接方式',
  'pages.material.material.categoryAtt.addAttribute': '新建规格属性',
  'pages.material.material.categoryAtt.addMaterialAtt': '新建品类属性',
  'pages.material.material.categoryAtt.deleteMsg':
    '此操作会删除 {field} {name},  将导致该 {name} 不可用 ',
  'pages.material.material.categoryAtt.value': '属性值',
  'pages.material.material.categoryAtt.addValue': '添加属性值',
  'pages.material.material.categoryAtt.blank': '空格',
  'pages.material.material.categoryAtt.linkedName': '是否拼接名称',
  'pages.material.material.categoryAtt.linkedSpec': '是否拼接规格',
  'pages.material.material.categoryAtt.basicAttribute': '基本属性',
  'pages.material.material.categoryAtt.specificationAtt': '规格属性',
  'pages.material.material.categoryAtt.unit': '基本单位',
  'pages.material.material.categoryAtt.volumeUnitId': '体积单位',
  'pages.material.material.categoryAtt.weightUnitId': '重量单位',
  'pages.material.material.categoryAtt.sizeUnitId': '尺寸单位',
  'pages.material.material.categoryAtt.g': '克(g)',
  'pages.material.material.categoryAtt.kg': '千克(kg)',
  'pages.material.material.categoryAtt.t': '吨(t)',
  'pages.material.material.categoryAtt.mm': '毫米(mm)',
  'pages.material.material.categoryAtt.cm': '厘米(cm)',
  'pages.material.material.categoryAtt.dm': '分米(dm)',
  'pages.material.material.categoryAtt.m': '米(m)',
  'pages.material.material.categoryAtt.m3': '立方米(m³)',
  'pages.material.material.categoryAtt.cm3': '立方厘米(cm³)',
  'pages.material.material.categoryAtt.onlyName': '名称',
  'pages.material.material.categoryAtt.lastCategory': '上级品类',
  'pages.material.material.categoryAtt.copy': '复制品类',
}

export default category
