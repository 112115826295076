const contractTemplateEditor = {
  'pages.contract.editor.field.shop': '卖家业务字段',
  'pages.contract.editor.field.product': '商品明细业务字段',
  'pages.contract.editor.field.customer': '买家业务字段',
  'pages.contract.editor.field.order': '订单业务字段',
  'pages.contract.editor.field.logoAndWatermark': 'logo水印设置',
  'pages.contract.editor.field.pageProps': '页面属性',
  'pages.contract.editor.field.componentProps': '组件属性',
  'pages.contract.editor.field.props.placeholder': '请选择组件',
  'pages.contract.editor.field.logo': 'logo',
  'pages.contract.editor.field.watermark': '水印',
  'pages.contract.editor.field.properties.paddingTop': '上边距',
  'pages.contract.editor.field.properties.paddingBottom': '下边距',
  'pages.contract.editor.field.properties.paddingLeft': '左边距',
  'pages.contract.editor.field.properties.paddingRight': '右边距',
  'pages.contract.editor.field.input': '自定义文本',
  'pages.contract.editor.field.textarea': '自定义文本域',
  'pages.contract.editor.field.table': '表格',
  'pages.contract.editor.field.productTable': '商品表格',
  'pages.contract.editor.field.seriesTable': '系列表格',
  'pages.contract.editor.field.properties.relationField': '关联字段',
  'pages.contract.editor.field.properties.width': '宽度',
  'pages.contract.editor.field.properties.height': '高度',
  'pages.contract.editor.field.properties.lineHeight': '行高',
  'pages.contract.editor.field.properties.fontSize': '字号',
  'pages.contract.editor.field.properties.bold': '加粗',
  'pages.contract.editor.field.properties.italic': '倾斜',
  'pages.contract.editor.field.properties.textAlign': '对齐方式',
  'pages.contract.editor.field.properties.border': '边框',
  'pages.contract.editor.field.properties.color': '字体颜色',
  'pages.contract.editor.field.properties.bgColor': '背景颜色',
}

export default contractTemplateEditor
