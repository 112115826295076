const category = {
  'pages.material.material.categoryAtt': '属性',
  'pages.material.material.categoryAtt.name': '属性名称',
  'pages.material.material.categoryAtt.categoryName': '品类名称',
  'pages.material.material.categoryAtt.copyCategory': '复制品类',
  'pages.material.material.categoryAtt.ofCategory': '所属品类',
  'pages.material.material.categoryAtt.addRoot': '新建一级品类',
  'pages.material.material.categoryAtt.addChild': '新建子品类',
  'pages.material.material.categoryAtt.editCategory': '编辑品类',
  'pages.material.material.categoryAtt.specification': '编辑规格属性',
  'pages.material.material.categoryAtt.deleteCategory': '删除品类',
  'pages.material.material.categoryAtt.category': '品类',
  'pages.material.material.categoryAtt.isRequired': '是否必填',
  'pages.material.material.categoryAtt.defaultValue': '默认值',
  'pages.material.material.categoryAtt.inputTypeId': '表单方式',
  'pages.material.material.categoryAtt.connectionType': '连接方式',
  'pages.material.material.categoryAtt.addAttribute': '新建规格属性',
  'pages.material.material.categoryAtt.addMaterialAtt': '新建品类属性',
  'pages.material.material.categoryAtt.deleteMsg':
    '此操作会删除 {field} {name},  将导致该 {name} 不可用 ',
  'pages.material.material.categoryAtt.value': '属性值',
  'pages.material.material.categoryAtt.addValue': '添加属性值',
  'pages.material.material.categoryAtt.blank': '空格',
  'pages.material.material.categoryAtt.linkedName': '是否拼接名称',
  'pages.material.material.categoryAtt.linkedSpec': '是否拼接规格',
  'pages.material.material.categoryAtt.basicAttribute': '基本属性',
  'pages.material.material.categoryAtt.specificationAtt': '规格属性',
  'pages.material.material.categoryAtt.unit': '基本单位',
  'pages.material.material.categoryAtt.volumeUnitId': '体积单位',
  'pages.material.material.categoryAtt.weightUnitId': '重量单位',
  'pages.material.material.categoryAtt.sizeUnitId': '尺寸单位',
  'pages.material.material.categoryAtt.g': '克(g)',
  'pages.material.material.categoryAtt.kg': '千克(kg)',
  'pages.material.material.categoryAtt.t': '吨(t)',
  'pages.material.material.categoryAtt.mm': '毫米(mm)',
  'pages.material.material.categoryAtt.cm': '厘米(cm)',
  'pages.material.material.categoryAtt.dm': '分米(dm)',
  'pages.material.material.categoryAtt.m': '米(m)',
  'pages.material.material.categoryAtt.m3': '立方米(m³)',
  'pages.material.material.categoryAtt.cm3': '立方厘米(cm³)',
  'pages.material.material.categoryAtt.onlyName': '名称',
  'pages.material.material.categoryAtt.lastCategory': '上级品类',
  'pages.material.material.categoryAtt.copy': '复制品类',
  'pages.material.material.categoryAtt.hint.attribute': '属性值重复,请修改',

  'pages.material.material.modal.confirm': '确认',
  'pages.material.material.modal.view.relevance.material': '查看关联物料',

  'pages.material.material.modal.delete.property': '删除属性',

  'pages.material.material.modal.first.hint': '是否确认删除属性{name}？',

  'pages.material.material.modal.second.hint.one':
    '属性<span style="color: #0070DD">{name}</span>已绑定<span style="color: #0070DD">{quantity}</span>个物料',
  'pages.material.material.modal.second.hint.two':
    '该品类下物料属性<span style="color: #0070DD">{name}</span>属性值一致，为<span style="color: #0070DD">{property}</span>，是否确认删除？',

  'pages.material.material.modal.third.hint.two':
    '该品类下物料属性<span style="color: #0070DD">{name}</span>属性值不一致，删除该规格属性后可能会出现相同属性值的物料，导致物料重复，无法删除。',
  'pages.material.material.modal.third.hint.three':
    '如需删除，请手动调整该品类下物料属性<span style="color: #0070DD">{name}</span>属性值一致。调整过程中可能会出现重复物料，需要手动删除',

  'pages.material.material.modal.fourth.hint.two':
    '该品类下物料属性<span style="color: #0070DD">{name}</span>属性值一致，为<span style="color: #0070DD">{property}</span>，删除该规格属性后所有物料将无规格，导致物料重复，无法删除。',
  'pages.material.material.modal.fourth.hint.three':
    '如需删除规格，确保该品类下只有唯一物料，请手动删除多余物料。',
  'pages.material.material.category.system.info': '系统信息',

  'pages.material.material.category.collapsePanel.stockInfo.hint.info':
    '创建物料时会将品类中维护的信息作为默认值; 修改品类中库存信息后，已存在物料的库存信息将不再同步更新。',

  'pages.material.material.category.collapsePanel.qualityInfo.hint.info':
    '创建物料时会将品类中维护的信息作为默认值; 修改品类中质量信息后，已存在物料的质量信息将不再同步更新。',

  'pages.material.material.category.collapsePanel.warranty.unit': '保质期单位',
  'pages.material.material.category.collapsePanel.warranty.period': '保质期周期',

  'pages.material.material.category.collapsePanel.warranty.options0': '日',
  'pages.material.material.category.collapsePanel.warranty.options1': '月',
  'pages.material.material.category.collapsePanel.warranty.options2': '年',

  'pages.material.material.category.collapsePanel.stockInfo.modal.title': '库存信息修改提示',
  'pages.material.material.category.collapsePanel.stockInfo.modal.hint1':
    '修改库存信息后，已存在物料的库存信息将不再同步更新。',
  'pages.material.material.category.collapsePanel.stockInfo.modal.hint2':
    '新建该品类下物料时会将修改后信息作为物料默认库存信息。',

  'pages.material.material.category.collapsePanel.qualityInfo.modal.title': '质量信息修改提示',
  'pages.material.material.category.collapsePanel.qualityInfo.modal.hint1':
    '修改质量信息后，已存在物料的质量信息将不再同步更新。',
  'pages.material.material.category.collapsePanel.qualityInfo.modal.hint2':
    '新建该品类下物料时会将修改后信息作为物料默认质量信息。',
}

export default category
