const user = {
  'pages.platform.organization.user.name': '用户名称',
  'pages.platform.organization.user.registration.time': '注册时间',
  'pages.platform.organization.user.last.login.time': '最后登录时间',
  'pages.platform.organization.user.organization.name': '组织名称',
  'pages.platform.organization.user.department': '部门',
  'pages.platform.organization.user.position': '职位',
  'pages.platform.organization.basic.information': '基本信息',
  'pages.platform.organization.user.organization': '组织',
  'pages.platform.organization.user.update.password': '修改密码',
  'pages.platform.organization.user.delete.user': '删除用户',
  'pages.platform.organization.user.delete.hint.one': '请确认您要删除这个用户。',
  'pages.platform.organization.user.delete.hint.two': '这个用户将会被彻底删除。',
  'pages.platform.organization.user.delete.hint.three':
    '如果您认为这个用户存在违规操作，通常是冻结，而不是删除。',
}

export default user
