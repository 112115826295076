const publicMaterial = {
  'pages.public.material.list.add.private': '添加到私库',
  'pages.public.material.list.add.material': '添加物料',
  'pages.public.material.list.add.material.hint':
    '本次共计划添加到私库物料{allNumber}条，{successNumber}条物料已添加成功，查询到{failNumber}条相同物料。',
  'pages.public.material.list.add.material.fail': '添加物料失败',
  'pages.public.material.list.modal.category.hint.one': '私库未查询到品类 {category}',
  'pages.public.material.list.modal.category.hint.two': '请确定是否创建品类',

  'pages.public.material.list.modal.category.hint.three': '该品类私库规格属性名称与公库一致',
  'pages.public.material.list.modal.category.hint.four': '可在校验过品牌系列后导入物料',

  'pages.public.material.list.modal.brand.hint.one': '私库未查询到品牌 {brand}',
  'pages.public.material.list.modal.brand.hint.two': '请确定是否创建品牌',
  'pages.public.material.list.modal.brand.hint.three':
    '所选物料品牌均已查询到，物料将自动创建到该品牌下',

  'pages.public.material.list.modal.series.hint.one': '私库未查询到系列 {series}',
  'pages.public.material.list.modal.series.hint.two': '请确定是否创建系列',
  'pages.public.material.list.modal.series.hint.three':
    '所选物料系列均已查询到，物料将自动创建到该系列下',
  'pages.public.material.list.modal.series.hint.four': '该物料在公库未设置系列',
  'pages.public.material.list.modal.add.material.fail.hint':
    '找到私库{category}品类属性与公库不一致，有以下两种解决方案：',
  'pages.public.material.list.modal.add.material.fail.hint.one':
    '1.在私库中修改该品类规格属性与使其与公库品类规格属性保持一致后可同步物料。',
  'pages.public.material.list.modal.add.material.fail.hint.two':
    '2.在私库中修改该品类名称或删除该品类后可从公库同步物料。',

  'pages.public.material.list.modal.add.material.fail.field.public.property.name': '公库属性名称',
  'pages.public.material.list.modal.add.material.fail.field.private.property.name':
    '私库库属性名称',

  'pages.public.material.list.modal.add.material.fail.private.update': '去私库修改',
  'pages.public.material.list.modal.add.material.fail.category.cabinet': '品类',

  'pages.public.material.list.add.device': '添加器件',
  'pages.public.material.list.add.device.hint.one':
    '本次共计划从公库添加到私库物料{allNumber}条,物料添加成功！',
  'pages.public.material.list.add.device.hint.two':
    '本次共计划添加到私库物料器件模型{allNumber}条，{successNumber}条器件模型已添加成功，查询到{failNumber}条相同器件模型。',
  'pages.public.material.list.add.device.hint.three':
    '相同器件模型不再同步，如需同步请修改诉苦器件模型名称。',

  'pages.public.material.list.add.end.hint.one': '本次共计划从公库添加到私库物料{allNumber}条',
  'pages.public.material.list.add.end.hint.two': '物料添加成功！器件模型添加成功！',

  'pages.public.material.list.synchronization.device.model': '同步器件模型',
  'pages.public.material.list.synchronization.device.model.fail':
    '本次共计划从公库现价到私库物料共{allNumber}条，物料添加失败！器件模型添加失败',

  'pages.public.material.list.synchronization.material.hint.one':
    '本次共计划添加到私库物料{allNumber}条，{successNumber}条物料已添加成功，私库查询到{failNumber}条相同物料',

  'pages.public.material.list.synchronization.material.hint.two.1':
    '本次共计划添加到私库物料{allNumber}条，{successNumber}条物料已添加成功，私库查询到{failNumber}条相同物料',
  'pages.public.material.list.synchronization.material.hint.two.2':
    '相同物料将不再同步。如需同步请去私库修改私库物料信息。',
  'pages.public.material.list.synchronization.material.hint.two.3':
    '点击同步器件模型，可进行器件模型同步。',

  'pages.public.material.list.synchronization.material.hint.three.1':
    '本次共计划从公库添加到私库物料{allNumber}条,',
  'pages.public.material.list.synchronization.material.hint.three.2':
    '{successNumber}条物料已添加成功，私库查询到{failNumber}条相同物料。',

  'pages.public.material.list.synchronization.device.model.hint.one.1':
    '本次共计划从公库添加到私库物料{allNumber}条,{successNumber}条物料已添加成功，私库查询到{failNumber}条相同物料。',
  'pages.public.material.list.synchronization.device.model.hint.one.2':
    '本次共计划添加到私库物料器件模型{allNumber}条，{successNumber}条器件模型已添加成功，查询到{failNumber}条相同器件模型。',
  'pages.public.material.list.synchronization.device.model.hint.one.3':
    '相同器件模型不再同步，如需同步请修改私库器件模型名称。',

  'pages.public.material.list.synchronization.device.model.hint.two':
    '本次共计划添加到私库物料器件模型{allNumber}条，查询到{failNumber}条相同器件模型。',

  'pages.public.material.list.synchronization.device.model.hint.three.1':
    '本次共计划从公库添加到私库物料{allNumber}条,{successNumber}条物料已添加成功，私库查询到{failNumber}条相同物料。',
  'pages.public.material.list.synchronization.device.model.hint.three.2':
    '本次共计划添加到私库物料器件模型{allNumber}条，{successNumber}条器件模型已添加成功，私库查询到{failNumber}条相同器件模型。',
}

export default publicMaterial
