const orderPay = {
  'pages.sale.transaction.order-pay.orderSubmitEnd': '订单提交成功!',
  'pages.sale.transaction.order-pay.fastPay': '请尽快完成支付！',
  'pages.sale.transaction.order-pay.orderNo': '订单号',
  'pages.sale.transaction.order-pay.please': '请在',
  'pages.sale.transaction.order-pay.endPay': '内完成支付,',
  'pages.sale.transaction.order-pay.endCancelOrder': '超时后取消订单',
  'pages.sale.transaction.order-pay.shouldPay': '应付金额(元)',
  'pages.sale.transaction.order-pay.payableAmount': '订单金额(元)',
  'pages.sale.transaction.order-pay.orderNumber': '订单编号',
  'pages.sale.transaction.order-pay.viewOrder': '查看订单',
  'pages.sale.transaction.order-pay.address': '收货地址',
  'pages.sale.transaction.order-pay.payType': '请选择以下方式付款',
  'pages.sale.transaction.order-pay.aliPay': '支付宝',
  'pages.sale.transaction.order-pay.qcCodeEnd': '距离二维码过期还有',
  'pages.sale.transaction.order-pay.qcCodeEndTime': '秒，过期后请刷新页面重新获取二维码',
  'pages.sale.transaction.order-pay.userAliPayInfo': '请使用支付宝扫一扫二维码，完成支付',
  'pages.sale.transaction.order-pay.h': '时',
  'pages.sale.transaction.order-pay.m': '分',
  'pages.sale.transaction.order-pay.s': '秒',
  'pages.sale.transaction.order-pay.orderPayEnd': '订单支付成功',
  'pages.sale.transaction.order-pay.orderPayEndInfo': '我们将尽快为你发货，收货期间请保持手机畅通',
  'pages.sale.transaction.order-pay.continueView': '继续逛逛',
  'pages.sale.transaction.order-pay.addresser': '收货人',
  'pages.sale.transaction.order-pay.phone': '联系电话',
  'pages.sale.transaction.order-pay.productTotal': '商品合计(元)',
  'pages.sale.transaction.order-pay.freight': '运费',
  'pages.sale.transaction.order-pay.discountAmount': '优惠金额(元)',
  'pages.sale.transaction.order-pay.actualPayment': '实付',
  'pages.sale.transaction.order-pay.orderListCustomer': '订单列表(客户)',
  'pages.sale.transaction.order-pay.refresh': '刷新',
  'pages.sale.transaction.order-pay.orderInfo': '订单信息',
  'pages.sale.transaction.order-pay.contact': '签署合同',
  'pages.sale.transaction.order-pay.orderPay': '订单支付',
  'pages.sale.transaction.order-pay.consigneePhone': '收货人电话',
  'pages.sale.transaction.order-pay.firstPartyContractNo': '甲方合同号',
  'pages.sale.transaction.order-pay.secondPartyContractNo': '乙方合同号',
  'pages.sale.transaction.order-pay.contractTypeName': '合同类型',
  'pages.sale.transaction.order-pay.stateName': '状态',
  'pages.sale.transaction.order-pay.firstPartySignatureTime': '签署日期',
  'pages.sale.transaction.order-pay.secondPartySignatureTime': '乙方签署日期',
  'pages.sale.transaction.order-pay.signContract': '签署合同',
  'pages.sale.transaction.order-pay.salesman': '销售负责人',
  'pages.sale.transaction.order-pay.salesPhone': '销售电话',
  'pages.sale.transaction.order-pay.salesCard': '销售名片',
  'pages.sale.transaction.order-pay.businessman': '商务负责人',
  'pages.sale.transaction.order-pay.businessPhone': '商务电话',
  'pages.sale.transaction.order-pay.businessCard': '商务名片',
  'pages.sale.transaction.order-pay.paperContract':
    '本次订单的合同类型为纸质合同，请线下签署，如还有疑问，请联系商务负责人进行咨询。',
  'pages.sale.transaction.order-pay.noRepeat':
    '支付密码不能是重复、连续的数字。如：123456、888888等。',
  'pages.sale.transaction.order-pay.payAmount': '支付金额',
  'pages.sale.transaction.order-pay.balancePay': '余额',
  'pages.sale.transaction.order-pay.usableBalance': '可用余额(元)',
  'pages.sale.transaction.order-pay.publicPay': '对公打款',
  'pages.sale.transaction.order-pay.balance': '余额',
  'pages.sale.transaction.order-pay.confirmPay': '确认支付',
  'pages.sale.transaction.order-pay.amount': '金额',
  'pages.sale.transaction.order-pay.inputAmountMsg': '大于0的金额',
  'pages.sale.transaction.order-pay.payAccountInfo': '充值账户信息',
  'pages.sale.transaction.order-pay.signatureTime': '签署合同',
  'pages.sale.transaction.order-pay.purchaseOrder': '机柜采购单',
  'pages.sale.transaction.order-pay.viewCard': '查看名片',
  'pages.sale.transaction.order-pay.paidAmount': '已付金额(元)',
  'pages.sale.transaction.order-pay.unpaidAmount': '未付金额(元)',
  'pages.sale.transaction.order-pay.paymentType': '支付方式',
  'pages.sale.transaction.order-pay.oneCopy': '一键复制',
  'pages.sale.transaction.order-pay.productAmount': '商品金额(元)',
  'pages.sale.transaction.order-pay.totalAmount': '订单总额(元)',
  'pages.sale.transaction.order-pay.initiateInfo1': '请您确认是否需要发起签署合同。',
  'pages.sale.transaction.order-pay.initiateInfo2':
    '发起后不能更改订单信息内容，双方签署后合同状态变更为【已签署】。',
  'pages.sale.transaction.order-pay.initiateInfo3':
    '若一方未签署时状态仍然为【签署中】，请及时提醒对方前往订单签署合同。',
  'pages.sale.transaction.order-pay.signInfo1': '请您确认该合同是否已经签署成功。',
  'pages.sale.transaction.order-pay.signInfo2':
    '确认签署成功系统将会重新获取签署状态，双方签署后合同状态变更为【已签署】。',
  'pages.sale.transaction.order-pay.signInfo3':
    '若一方未签署时状态仍然为【签署中】，请及时提醒对方前往订单签署合同',
  'pages.sale.transaction.order-pay.depositAccount': '预存账户',
  'pages.sale.transaction.order-pay.initiateSign': '发起签署',
  'pages.sale.transaction.order-pay.confirmSign': '签署合同确认',

  'pages.sale.transaction.order-success.unpaidAmount': '待付金额(元)',
  'pages.sale.transaction.order-success.freight': '运费金额(元)',
  'pages.sale.transaction.order-success.productAmountInfo': '商品金额是订单所有商品优惠前的总价',
  'pages.sale.transaction.order-success.totalAmountInfo':
    '订单金额 = (商品金额 - 优惠金额 - 优惠券) + (运费金额 - 运费券) + 定制服务费(定制服务费包括粉末定制费，粉末加工费，并柜服务费)',
}

export default orderPay
