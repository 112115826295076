const shopCart = {
  'pages.sale.transaction.shopCart.subtract': '促销立减：',
  'pages.sale.transaction.shopCart.actual.payment': '实付款：',
  'pages.sale.transaction.shopCart.actual.productName': '商品名称',
  'pages.sale.transaction.shopCart.actual.materialNo': '物料编号',
  'pages.sale.transaction.shopCart.actual.categoryName': '品类',
  'pages.sale.transaction.shopCart.actual.specification': '规格',
  'pages.sale.transaction.shopCart.actual.orderNo': '订货号',
  'pages.sale.transaction.shopCart.actual.brandName': '品牌',
  'pages.sale.transaction.shopCart.actual.price': '单价(元)',
  'pages.sale.transaction.shopCart.actual.onlyPrice': '价格',
  'pages.sale.transaction.shopCart.actual.discountPrice': '优惠后单价(元)',
  'pages.sale.transaction.shopCart.actual.quantity': '数量',
  'pages.sale.transaction.shopCart.actual.subtotalAmount': '金额小计(元)',
  'pages.sale.transaction.shopCart.actual.expectedTime': '期望到货日期',
  'pages.sale.transaction.shopCart.actual.submitOrder': '提交订单',
  'pages.sale.transaction.shopCart.actual.salesOrderNo': '订单编号',
  'pages.sale.transaction.shopCart.actual.createdTime': '订单创建时间',
  'pages.sale.transaction.shopCart.actual.sourceTypeName': '订单来源',
  'pages.sale.transaction.shopCart.actual.clearingFormName': '结算方式',
  'pages.sale.transaction.shopCart.actual.totalAmount': '订单金额',
  'pages.sale.transaction.shopCart.actual.discountAmount': '优惠金额(元)',
  'pages.sale.transaction.shopCart.actual.paidAmount': '订单实付金额',
  'pages.sale.transaction.shopCart.actual.paymentStateName': '支付状态',
  'pages.sale.transaction.shopCart.actual.unpaidAmount': '待支付金额',
  'pages.sale.transaction.shopCart.actual.shipStateName': '发货状态',
  'pages.sale.transaction.shopCart.actual.stateName': '订单状态',
  'pages.sale.transaction.shopCart.actual.productName1': '产品名称',
  'pages.sale.transaction.shopCart.actual.basicInfo': '基本信息',
  'pages.sale.transaction.shopCart.actual.cabinetSpecification': '机柜规格',
  'pages.sale.transaction.shopCart.actual.seriesName': '产品系列',
  'pages.sale.transaction.shopCart.actual.seriesName1': '系列',
  'pages.sale.transaction.shopCart.actual.materialQuality': '材质',
  'pages.sale.transaction.shopCart.actual.argClassProtect': '防护等级',
  'pages.sale.transaction.shopCart.actual.argShock': '抗冲击',
  'pages.sale.transaction.shopCart.actual.argSaltFog': '盐雾测试',
  'pages.sale.transaction.shopCart.actual.argCe': 'CE认证',
  'pages.sale.transaction.shopCart.actual.payPrice': '支付金额',
  'pages.sale.transaction.shopCart.actual.toPay': '去结算',
  'pages.sale.transaction.shopCart.actual.allPrice': '总价(元)',
  'pages.sale.transaction.shopCart.actual.confirmDownOrder': '确认下单',
  'pages.sale.transaction.shopCart.actual.updateSet': '更新配置',
  'pages.sale.transaction.shopCart.actual.orderPay': '订单结算',
  'pages.sale.transaction.shopCart.actual.materialShortNo': '订货代码',
  'pages.sale.transaction.shopCart.actual.estimatedShippingTime': '预计发货日期',
  'pages.sale.transaction.shopCart.actual.unit': '单位',
  'pages.sale.transaction.shopCart.actual.discountEndPrice': '优惠后单价(元)',
  'pages.sale.transaction.shopCart.actual.discountEndAllPrice': '优惠后总计(元)',
  'pages.sale.transaction.shopCart.actual.color': '颜色',
  'pages.sale.transaction.shopCart.actual.material': '材质',
  'pages.sale.transaction.shopCart.actual.surfaceTreatment': '表面处理',
  'pages.sale.transaction.shopCart.actual.direction': '开门方向',
  'pages.sale.transaction.shopCart.actual.remark': '备注',
  'pages.sale.transaction.shopCart.actual.serveName': '服务名称',
  'pages.sale.transaction.shopCart.actual.quantityUnit': '数量(个/柜)',
  'pages.sale.transaction.shopCart.actual.amountUnit': '金额(元)',
  'pages.sale.transaction.shopCart.actual.subtotal': '小计(元/柜)',
  'pages.sale.transaction.shopCart.actual.additionalServe': '附加服务',
  'pages.sale.transaction.shopCart.actual.name': '名称',
  'pages.sale.transaction.shopCart.actual.down': '已下架',
  'pages.sale.transaction.shopCart.actual.addCabinet': '去选型',
  'pages.sale.transaction.shopCart.actual.promotion': '营销活动',
  'pages.sale.transaction.shopCart.actual.originalPrice': '原价(元)',
  'pages.sale.transaction.shopCart.actual.cabinetTypeName': '商品类型',
  'pages.sale.transaction.shopCart.actual.remarkEdit': '编辑备注',
  'pages.sale.transaction.shopCart.actual.remarkPlaceholder': '请输入备注内容',
  'pages.sale.transaction.shopCart.actual.remarkTips': '最多可输入500个字符',
  'pages.sale.transaction.shopCart.actual.remarkWarn': '备注最多支持输入500个字符，请精简输入内容',
}

export default shopCart
