const doc = {
  'pages.material.document.type.document': '文档',
  'pages.material.document.type.title': '文档类型',
  'pages.material.document.type.field.number': '文档类型编号',
  'pages.material.document.type.field.name': '文档类型',
  'pages.material.document.type.field.format': '文档格式',
  'pages.material.document.list.title': '文档列表',
  'pages.material.document.list.field.name': '名称',
  'pages.material.document.list.field.categoryId': '品类',
  'pages.material.document.list.field.documentNo': '编号',
  'pages.material.document.list.field.documentType': '类型',
  'pages.material.document.list.field.purpose': '用途',
  'pages.material.document.list.field.documentVersion': '版本',
  'pages.material.document.list.field.approveStateName': '审核状态',
  'pages.material.document.list.field.approverName': '审核人',
  'pages.material.document.list.field.releaseStateName': '启用状态',
  'pages.material.document.list.field.chartNo': '图号',
  'pages.material.document.list.field.format': '格式',
  'pages.material.document.list.field.size': '大小',
  'pages.material.document.list.field.materialList': '关联物料',
  'pages.material.document.list.field.materialName': '物料名称',
  'pages.material.document.list.field.creatorName': '上传人',
  'pages.material.document.list.field.createdTime': '上传时间',
  'pages.material.document.list.field.approvedTime': '审核时间',
  'pages.material.document.list.field.approveOpinion': '审核意见',
  'pages.material.document.list.field.description': '描述',
  'pages.material.document.list.field.kingdeeNo': 'ERP编码',
  'pages.material.document.list.field.updaterName': '更新人',
  'pages.material.document.list.field.updatedTime': '更新时间',
  'pages.material.document.list.field.preview.document': '预览文档',
  'pages.material.document.list.state.release.0': '未发布',
  'pages.material.document.list.state.release.1': '已发布',
  'pages.material.document.list.state.release.2': '停用',
  'pages.material.document.list.state.approve.0': '草稿',
  'pages.material.document.list.state.approve.1': '待审核',
  'pages.material.document.list.state.approve.2': '审核通过',
  'pages.material.document.list.state.approve.3': '审核驳回',
  'pages.material.document.list.tab.waitReview': '待审核',
  'pages.material.document.list.tab.reviewing': '审核中',
  'pages.material.document.list.tab.approved': '审核通过',
  'pages.material.document.list.tab.rejected': '审核驳回',
  'pages.material.document.list.action.syncMaterial': '同步物料',
  'pages.material.document.list.action.createMaterial': '新建物料',
  'pages.material.document.list.action.uploadDocument': '上传文档',
  'pages.material.document.list.action.batchSubmitReview': '提交审核',
  'pages.material.document.list.action.batchRevertReview': '撤销审核',
  'pages.material.document.list.action.batchRelease': '启用',
  'pages.material.document.list.action.batchDisable': '停用',
  'pages.material.document.list.action.batchDelete': '删除',
  'pages.material.document.list.drawer.moreVersion': '更多版本',
  'pages.material.document.list.drawer.title': '上传文档',
  'pages.material.document.list.drawer.message.uploadDocument': '请上传文档',
  'pages.material.document.list.drawer.collapse.info': '文档信息',
  'pages.material.document.list.drawer.collapse.material': '关联物料',
  'pages.material.document.list.drawer.collapse.group': '所属分组',
  'pages.material.document.list.drawer.collapse.version': '版本：',
  'pages.material.document.list.drawer.material.title': '绑定物料',
  'pages.material.document.list.drawer.material.field.name': '物料名称',
  'pages.material.document.list.drawer.material.field.materialNo': '物料编号',
  'pages.material.document.list.drawer.material.field.categoryId': '品类',
  'pages.material.document.list.drawer.material.field.materialAttribute': 'MRP 类型',
  'pages.material.document.list.drawer.material.field.materialType': '物料类型',
  'pages.material.document.list.drawer.material.field.materialUnit': '物料单位',
  'pages.material.document.list.drawer.material.field.materialDesc': '物料描述',
  'pages.material.document.list.drawer.material.field.brandId': '品牌',
  'pages.material.document.list.drawer.material.field.seriesNameId': '系列',
  'pages.material.document.list.drawer.material.field.state': '物料状态',
  'pages.material.document.list.drawer.material.field.height': '高(mm)',
  'pages.material.document.list.drawer.material.field.width': '宽(mm)',
  'pages.material.document.list.drawer.material.field.depth': '深(mm)',
  'pages.material.document.list.drawer.material.field.material': '材质',
  'pages.material.document.list.drawer.material.field.color': '颜色',
  'pages.material.document.list.drawer.material.field.thickness': '厚度',
  'pages.material.document.list.drawer.material.field.surface': '表面处理',
  'pages.material.document.list.drawer.material.field.packageUnit': '包装单元',
  'pages.material.document.list.drawer.action.addMaterial': '添加物料',
  'pages.material.document.list.drawer.action.addVersion': '添加版本',
  'pages.material.document.list.drawer.action.deleteVersion': '删除版本',
  'pages.material.document.list.drawer.action.reviewOpinion': '审核意见',
  'pages.material.document.list.drawer.action.opinionPlaceholder': '请输入意见',
  'pages.material.document.list.drawer.action.pass': '通过',
  'pages.material.document.list.drawer.action.revert': '驳回',
  'pages.material.document.list.drawer.tip.chooseIndexDocumentType':
    '请选择第 {field} 行的文档类型',
  'pages.material.document.list.drawer.tip.inputDocumentVersion': '请填写第 {field} 行的文档版本',

  'pages.material.document.group.field.name': '名称',
  'pages.material.document.group.field.brand': '品牌',
  'pages.material.document.group.field.series': '系列',
  'pages.material.document.group.field.category': '品类',
  'pages.material.document.group.model.add.document.group': '新建文档分组',
  'pages.material.document.group.model.delete.document.group': '删除文档分组',
  'pages.material.document.group.model.delete.hint.one': '请确认您要删除这个文档分组。',
  'pages.material.document.group.model.delete.hint.two':
    '删除该文档分组后,文档分组中文档与物料的绑定关系将同步删除，无法恢复。',
  'pages.material.document.group.model.delete.hint.three':
    '通常文档和物料绑定错误,通过移除文档、物料进行重新添加来解决。',
  'pages.material.document.group.drawer.basic.info': '基本信息',
  'pages.material.document.group.drawer.relevance.document': '关联文档',
  'pages.material.document.group.drawer.relevance.material': '关联物料',
  'pages.material.document.group.drawer.select.document': '选择文档',
  'pages.material.document.group.drawer.add.material': '添加物料',
  'pages.material.document.group.drawer.document.field.documentNo': '文档编号',
  'pages.material.document.group.drawer.document.field.name': '文档名称',
  'pages.material.document.group.drawer.document.field.chartNo': '文档图号',
  'pages.material.document.group.drawer.document.field.format': '文档格式',
  'pages.material.document.group.drawer.document.field.documentCategoryName': '文档类型',
  'pages.material.document.group.drawer.document.field.documentTypeName': '文档类型',
  'pages.material.document.group.drawer.document.field.documentVersion': '文档版本',
  'pages.material.document.group.drawer.document.field.approveState': '审核状态',
  'pages.material.document.group.drawer.document.field.approverName': '审核人',
  'pages.material.document.group.drawer.document.field.releaseState': '启用状态',
  'pages.material.document.group.drawer.document.field.purpose': '文档用途',
  'pages.material.document.group.drawer.document.field.description': '文档描述',
  'pages.material.document.group.drawer.document.field.download': '下载',
  'pages.material.document.group.drawer.material.field.name': '物料名称',
  'pages.material.document.group.drawer.material.field.materialNo': '物料编号',
  'pages.material.document.group.drawer.material.field.materialShortNo': '订货代码',
  'pages.material.document.group.drawer.material.field.specification': '规格',
  'pages.material.document.group.drawer.material.field.categoryName': '品类',
  'pages.material.document.group.drawer.material.field.approveStateName': '物料状态',
  'pages.material.document.group.drawer.material.field.relevance.state': '关联状态',
  'pages.material.document.group.drawer.document.associated': '已关联',
  'pages.material.document.group.drawer.document.not.associated': '未关联',
  'pages.material.document.group.drawer.delete.document.group': '删除文档分组',
  'pages.material.document.group.drawer.material.modal.relevancy': '添加',
  'pages.material.document.group.drawer.material.modal.continuous.relevancy': '连续添加',
  'pages.material.document.group.drawer.material.modal.relieve.relevancy': '移除',
  'pages.material.document.group.drawer.document.field.drawer.documentNo': '编号',
  'pages.material.document.group.drawer.document.field.drawer.chartNo': '图号',
  'pages.material.document.group.drawer.document.field.drawer.documentVersion': '版本',
  'pages.material.document.group.drawer.document.field.drawer.name': '名称',
  'pages.material.document.group.drawer.document.field.drawer.documentCategoryName': '类型',
  'pages.material.document.group.drawer.document.field.drawer.approveStateName': '审核状态',
  'pages.material.document.group.drawer.document.field.drawer.format': '文件格式',
  'pages.material.document.group.drawer.document.field.drawer.releaseStateName': '启用状态',
  'pages.material.document.group.drawer.document.field.drawer.size': '大小',
  'pages.material.document.group.drawer.document.field.drawer.description': '描述',
  'pages.material.document.group.drawer.document.field.drawer.audit.person': '审核人',
  'pages.material.document.group.drawer.document.field.drawer.audit.time': '审核时间',
  'pages.material.document.list.field.standard': '是否标准品',

  'pages.material.document.list.field.documentSourceName': '文档来源',
  'pages.material.document.list.field.documentSourceName0': '手动上传',
  'pages.material.document.list.field.documentSourceName1': 'DFM同步',
}
export default doc
