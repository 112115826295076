const shippingTable = {
  'pages.order.components.contract.firstPartyContractNo': '甲方合同号',
  'pages.order.components.contract.secondPartyContractNo': '乙方合同号',
  'pages.order.components.contract.contractModeName': '合同方式',
  'pages.order.components.contract.contractTypeName': '合同类型',
  'pages.order.components.contract.contractTemplateName': '合同模板名称',
  'pages.order.components.contract.stateName': '状态',
  'pages.order.components.contract.firstPartySignatureTime': '甲方签署日志',
  'pages.order.components.contract.secondPartySignatureTime': '乙方签署日志',
}

export default shippingTable
