const paymentTable = {
  'pages.order.components.payment.paymentFlowNo': '支付流水号',
  'pages.order.components.payment.salesOrderNo': '支付单号',
  'pages.order.components.payment.paymentChannelName': '支付方式',
  'pages.order.components.payment.paymentTypeName': '支付类型',
  'pages.order.components.payment.paymentAmount': '支付金额(元)',
  'pages.order.components.payment.restReceivableAmount': '剩余应收(元)',
  'pages.order.components.payment.paymentTime': '支付时间',
}

export default paymentTable
