const plaformOrganization = {
  'pages.platform.organization.organization.name': '组织名称',
  'pages.platform.organization.organization.owner': '拥有者',
  'pages.platform.organization.organization.owner.phone': '拥有者电话',
  'pages.platform.organization.organization.main.verify': '主体认证',
  'pages.platform.organization.organization.abbreviation.name': '组织简称',
  'pages.platform.organization.organization.code': '组织代码',
  'pages.platform.organization.organization.concat.name': '联系人',
  'pages.platform.organization.organization.concat.phone': '联系电话',
  'pages.platform.organization.organization.approval.status': '审批状态',
  'pages.platform.organization.organization.basic.information': '基本信息',
  'pages.platform.organization.organization.invoice.information': '开票信息',
  'pages.platform.organization.organization.company.name': '公司名称',
  'pages.platform.organization.organization.company.address': '公司地址',
  'pages.platform.organization.organization.company.detail.address': '详细地址',
  'pages.platform.organization.organization.social.credit.code': '统一社会信用代码',
  'pages.platform.organization.organization.company.register.address': '公司注册地址',
  'pages.platform.organization.organization.company.register.phone': '公司注册电话',
  'pages.platform.organization.organization.deposit.bank': '开户银行',
  'pages.platform.organization.organization.deposit.bank.account': '开户银行账户',
  'pages.platform.organization.organization.company.certificate.address': '证件上所在城市',
  'pages.platform.organization.organization.corporate.juridical.person': '法定代表人',
  'pages.platform.organization.organization.corporate.identity.card': '法人身份证号码',
  'pages.platform.organization.organization.business.license': '营业执照',
  'pages.platform.organization.organization.official.letter': '认证公函',
  'pages.platform.organization.organization.menu.configuration': '菜单配置',
  'pages.platform.organization.organization.company.approve': '企业审批',
  'pages.platform.organization.organization.reason': '原因',
  'pages.platform.organization.organization.approve': '审批通过',
  'pages.platform.organization.organization.approve.reject': '审批驳回',
  'pages.platform.organization.organization.reject': '驳回',
  'pages.platform.organization.organization.delete.organization': '删除组织',
  'pages.platform.organization.organization.delete.hint.one': '请确认您要删除这个组织。',
  'pages.platform.organization.organization.delete.hint.two':
    '这个组织将会被彻底删除。包括组织内的员工。',
  'pages.platform.organization.organization.delete.hint.three':
    '如果您认为这个组织存在违规操作，通常是冻结，而不是删除。',
  'pages.platform.organization.organization.product.package': '产品包配置',
  'pages.platform.organization.organization.product.package.name': '产品包名称',
  'pages.platform.organization.organization.product.package.type': '类型',
  'pages.platform.organization.organization.product.package.seats': '开通席位数',
  'pages.platform.organization.organization.product.package.default.authorization': '是否默认授权',
  'pages.platform.organization.organization.product.package.authorization': '是否授权',

  'pages.platform.organization.organization.product.package.modal.authorization': '授权',
  'pages.platform.organization.organization.product.package.authorization.version': '授权版本',
  'pages.platform.organization.organization.product.package.authorization.version.name':
    '授权版本名称',
}

export default plaformOrganization
